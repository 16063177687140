import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ValidateImeiResponse} from "../model/validateImeiResponse";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {ImeiInfo} from "../model/imeiInfo";
import {UpdateDevicesRequest} from "../model/updateDevicesRequest";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient) { }

  validateImeis(imeis: string[]) : Observable<ValidateImeiResponse[]> {
    return this.http.get<ValidateImeiResponse[]>(environment.apiUrlPrefix + 'device/validate', {
      params: {
        imeis: imeis.join(',')
      }
    });
  }

  updateDevices(request: UpdateDevicesRequest[]) : Observable<ImeiInfo> {
    return this.http.patch<ImeiInfo>(environment.apiUrlPrefix + 'devices', request);
  }

  deleteDevices(imeiInfoIds: number[]) {
    return this.http.delete(environment.apiUrlPrefix + 'devices', {
      params: {
        imeiInfoIds: imeiInfoIds
      },
      observe: 'response'
    });
  }
}
