import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  createCustomer(emailAddress: string) : Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const options: Object = {
      headers,
      responseType: 'text'
    }

    return this.http.post<string>(environment.apiUrlPrefix + 'customer/' + emailAddress, {}, options);
  }
}
