import {Component, inject} from '@angular/core';
import {
  COMPANY_NAME_MAX_CHARS,
  FIRST_NAME_MAX_CHARS,
  LAST_NAME_MAX_CHARS,
  Order, PHONE_NUMBER_MAX_CHARS, TEAM_NAME_MAX_CHARS
} from "../../model/order";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {NgIf} from "@angular/common";
import {convert} from "../../utils/OrderFormConverter";
import {DeviceService} from "../../service/device.service";
import {
  processEmergencyContactChange, processFormSelectionChange
} from "../../utils/OrderFormValidators";
import {catchError, of, tap} from "rxjs";
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "../../../environments/environment";
import {ImeiInfo} from "../../model/imeiInfo";
import {
  formatInvalidImeiStatuses,
  InvalidImeiMessages
} from "../../utils/ImeiInvalidStatusFormatter";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {OrderTypes} from "../../utils/OrderTypeConstants";
import {
  ADDRESS2_MAX_CHARS,
  ADDRESS_MAX_CHARS, CITIZENSHIP_MAX_CHARS,
  CITY_MAX_CHARS, COUNTRY_MAX_CHARS,
  STATE_MAX_CHARS, ZIP_CODE_MAX_CHARS
} from "../../model/address";
import {
  ADDITIONAL_INFO_MAX_CHARS,
  EC1_COUNTRY_MAX_CHARS,
  EC1_EMAIL_MAX_CHARS,
  EC1_NAME_MAX_CHARS,
  EC1_PHONE_NUMBER_MAX_CHARS,
  EC2_COUNTRY_MAX_CHARS,
  EC2_EMAIL_MAX_CHARS, EC2_NAME_MAX_CHARS,
  EC2_PHONE_NUMBER_MAX_CHARS,
  EC_EMAIL_MAX_CHARS,
  EC_FIRST_NAME_MAX_CHARS,
  EC_LAST_NAME_MAX_CHARS,
  EC_PHONE_NUMBER_MAX_CHARS
} from "../../model/emergencyContact";

@Component({
  selector: 'app-order-form',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    CdkTextareaAutosize
  ],
  templateUrl: './order-form.component.html',
  styleUrl: './order-form.component.css'
})
export class OrderFormComponent {
  // services
  deviceService: DeviceService = inject(DeviceService);
  authService: AuthService = inject(AuthService);

  // flags for managing display
  formSubmitted: boolean = false;
  processingSubmit: boolean = false;
  validationDisabled: boolean = true;
  imeiValidationResult: boolean = false;

  // errors to display under IMEI input
  malformedImeiMessage: string | null = null;
  notFoundImeiMessage: string | null = null;
  deactivateImeiMessage: string | null = null;
  activeOnHubMessage: string | null = null;
  // error to display if IMEIs are invalid on submit
  postSubmitError: boolean = false;
  postSubmitImeiError: boolean = false;

  orderForm: FormGroup = this.createForm();
  formSelection: string | null = null;
  orderNumber: number | null = null;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.imeis?.valueChanges.subscribe(change => {
      if (change !== null) {
        this.validationDisabled = false;
      }
    });

    this.updateEmergencyContact?.valueChanges.subscribe(change => {
      processEmergencyContactChange(change, this.orderForm);
    });
  }

  public validateImeis() {
    let imeisString: string = this.orderForm.controls['imeis'].value;

    let imeisArray: string[];
    try {
      imeisArray = imeisString.split(/[\n\s\t,]/).filter((imei) => imei && imei.length > 0);
    } catch (e) {
      console.log(e);
      this.malformedImeiMessage = "List is incorrectly formatted. Please provide a comma-separated list of valid IMEIs.";
      this.imeiValidationResult = false;
      return;
    }

    if (imeisArray.length === 0) {
      console.log("WARN - No IMEIs provided.");
      this.malformedImeiMessage = "Please provide a comma-separated list of valid IMEIs.";
      this.imeiValidationResult = false;
      return;
    }

    // don't allow user to click validate button while request is being made
    this.validationDisabled = true;
    this.deviceService.validateImeis(imeisArray).subscribe(responses => {
      this.processImeiStatuses(responses.map(response => response.imeiInfo));
      this.validationDisabled = false;
    });
  }

  selectForm(formSelection: string) {
    this.formSelection = formSelection;
    processFormSelectionChange(formSelection, this.orderForm, false);
  }

  submit() {
    this.processingSubmit = true;
    this.postSubmitError = false;
    this.postSubmitImeiError = false;
    this.authService.user$.subscribe(user => {
      let order = convert(this, this.formSelection!, user!.email!);
      this.saveForm(order).subscribe(response => {
        if (!response) {
          this.processingSubmit = false;
          this.postSubmitError = true;
          if (this.hasErrorMessage()) {
            this.postSubmitImeiError = true;
          }
        }
      });
    });
  }

  refreshForm(){
    this.formSelection = null;
    this.formSubmitted = false;
    this.processingSubmit = false;
    this.validationDisabled = true;
    this.imeiValidationResult = false;
    this.malformedImeiMessage = null;
    this.notFoundImeiMessage = null;
    this.deactivateImeiMessage = null;
    this.activeOnHubMessage = null;
    this.postSubmitError = false;
    this.postSubmitImeiError = false;
    this.formSelection = null;
    this.orderNumber = null;
    this.orderForm = this.createForm();

    this.imeis?.valueChanges.subscribe(change => {
      if (change !== null) {
        this.validationDisabled = false;
      }
    });

    this.updateEmergencyContact?.valueChanges.subscribe(change => {
      processEmergencyContactChange(change, this.orderForm);
    });
  }

  hasErrorMessage(): boolean {
    return !!(this.malformedImeiMessage || this.deactivateImeiMessage || this.notFoundImeiMessage || this.activeOnHubMessage);
  }

  private saveForm(order: Order) {
    return this.http.post<Order>(environment.apiUrlPrefix + 'order', order, {observe: 'response'})
      .pipe(
        tap(response => {
          this.orderNumber = response.body!.orderId;
          this.formSubmitted = true;
        }),
        catchError(err => {
          if (Array.isArray(err.error)) {
            const invalidImeis: ImeiInfo[] = err.error;
            this.processImeiStatuses(invalidImeis);
          }

          return of(false);
        })
      );
  }

  private processImeiStatuses(imeis: ImeiInfo[]) {
    let invalidImeiMessages: InvalidImeiMessages = formatInvalidImeiStatuses(imeis);
    this.malformedImeiMessage = invalidImeiMessages.malformedImeiMessage;
    this.notFoundImeiMessage = invalidImeiMessages.notFoundImeiMessage;
    this.deactivateImeiMessage = invalidImeiMessages.deactivateImeiMessage;
    this.activeOnHubMessage = invalidImeiMessages.activeOnHubMessage;

    if (!this.hasErrorMessage()) {
      this.imeiValidationResult = true;
      this.postSubmitError = false;
      this.postSubmitImeiError = false;
    }
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.maxLength(FIRST_NAME_MAX_CHARS)]],
      lastName: [null, [Validators.required, Validators.maxLength(LAST_NAME_MAX_CHARS)]],
      companyName: [null, [Validators.required, Validators.maxLength(COMPANY_NAME_MAX_CHARS)]],
      updateMainPoc: [false],
      hardwareModel: [null],
      hardwareQuantity: [null, Validators.min(1)],
      servicePlan: [null],
      phoneNumber: [null],
      streetAddress: [null],
      streetAddress2: [null],
      city: [null],
      state: [null],
      postalCode: [null],
      country: [null],
      imeis: [],
      teamName: [null, [Validators.maxLength(TEAM_NAME_MAX_CHARS)]],
      updateEmergencyContact: [false, [Validators.required]],
      ecFirstName: [null],
      ecLastName: [null],
      ecStreetAddress: [null],
      ecCity: [null],
      ecState: [null],
      ecPostalCode: [null],
      ecCountry: [null],
      ecCitizenship: [null],
      ecPhone: [null],
      ecEmail: [null],
      ecAdditionalInfo: [null],
      ec1Name: [null],
      ec1Country: [null],
      ec1Phone: [null],
      ec1Email: [null],
      ec2Name: [null],
      ec2Country: [null],
      ec2Phone: [null],
      ec2Email: [null],
      acknowledge: [false, [Validators.requiredTrue]],
      acknowledgeTransfer: [false]
    });
  }

  get firstName() {
    return this.orderForm.get('firstName');
  }

  get lastName() {
    return this.orderForm.get('lastName');
  }

  get companyName() {
    return this.orderForm.get('companyName');
  }

  get updateMainPoc() {
    return this.orderForm.get('updateMainPoc');
  }

  get hardwareModel() {
    return this.orderForm.get('hardwareModel');
  }

  get hardwareQuantity() {
    return this.orderForm.get('hardwareQuantity');
  }

  get servicePlan() {
    return this.orderForm.get('servicePlan');
  }

  get phoneNumber() {
    return this.orderForm.get('phoneNumber');
  }

  get streetAddress() {
    return this.orderForm.get('streetAddress');
  }

  get streetAddress2() {
    return this.orderForm.get('streetAddress2');
  }

  get city() {
    return this.orderForm.get('city');
  }

  get state() {
    return this.orderForm.get('state');
  }

  get postalCode() {
    return this.orderForm.get('postalCode');
  }

  get country() {
    return this.orderForm.get('country');
  }

  get imeis() {
    return this.orderForm.get('imeis');
  }

  get teamName() {
    return this.orderForm.get('teamName');
  }

  get updateEmergencyContact() {
    return this.orderForm.get('updateEmergencyContact');
  }

  get ecFirstName() {
    return this.orderForm.get('ecFirstName');
  }

  get ecLastName() {
    return this.orderForm.get('ecLastName');
  }

  get ecStreetAddress() {
    return this.orderForm.get('ecStreetAddress');
  }

  get ecCity() {
    return this.orderForm.get('ecCity');
  }

  get ecState() {
    return this.orderForm.get('ecState');
  }

  get ecPostalCode() {
    return this.orderForm.get('ecPostalCode');
  }

  get ecCountry() {
    return this.orderForm.get('ecCountry');
  }

  get ecCitizenship() {
    return this.orderForm.get('ecCitizenship');
  }

  get ecPhone() {
    return this.orderForm.get('ecPhone');
  }

  get ecEmail() {
    return this.orderForm.get('ecEmail');
  }

  get ecAdditionalInfo() {
    return this.orderForm.get('ecAdditionalInfo');
  }

  get ec1Name() {
    return this.orderForm.get('ec1Name');
  }

  get ec1Country() {
    return this.orderForm.get('ec1Country');
  }

  get ec1Phone() {
    return this.orderForm.get('ec1Phone');
  }

  get ec1Email() {
    return this.orderForm.get('ec1Email');
  }

  get ec2Name() {
    return this.orderForm.get('ec2Name');
  }

  get ec2Country() {
    return this.orderForm.get('ec2Country');
  }

  get ec2Phone() {
    return this.orderForm.get('ec2Phone');
  }

  get ec2Email() {
    return this.orderForm.get('ec2Email');
  }

  get acknowledge() {
    return this.orderForm.get('acknowledge');
  }

  get acknowledgeTransfer() {
    return this.orderForm.get('acknowledgeTransfer');
  }

  protected readonly NEW = OrderTypes.NEW;
  protected readonly EXISTING = OrderTypes.EXISTING;
  protected readonly FIRST_NAME_MAX_CHARS = FIRST_NAME_MAX_CHARS;
  protected readonly LAST_NAME_MAX_CHARS = LAST_NAME_MAX_CHARS;
  protected readonly COMPANY_NAME_MAX_CHARS = COMPANY_NAME_MAX_CHARS;
  protected readonly TEAM_NAME_MAX_CHARS = TEAM_NAME_MAX_CHARS;
  protected readonly PHONE_NUMBER_MAX_CHARS = PHONE_NUMBER_MAX_CHARS;
  protected readonly ADDRESS_MAX_CHARS = ADDRESS_MAX_CHARS;
  protected readonly ADDRESS2_MAX_CHARS = ADDRESS2_MAX_CHARS;
  protected readonly CITY_MAX_CHARS = CITY_MAX_CHARS;
  protected readonly STATE_MAX_CHARS = STATE_MAX_CHARS;
  protected readonly ZIP_CODE_MAX_CHARS = ZIP_CODE_MAX_CHARS;
  protected readonly COUNTRY_MAX_CHARS = COUNTRY_MAX_CHARS;
  protected readonly EC_FIRST_NAME_MAX_CHARS = EC_FIRST_NAME_MAX_CHARS;
  protected readonly EC_LAST_NAME_MAX_CHARS = EC_LAST_NAME_MAX_CHARS;
  protected readonly CITIZENSHIP_MAX_CHARS = CITIZENSHIP_MAX_CHARS;
  protected readonly EC_PHONE_NUMBER_MAX_CHARS = EC_PHONE_NUMBER_MAX_CHARS;
  protected readonly EC_EMAIL_MAX_CHARS = EC_EMAIL_MAX_CHARS;
  protected readonly ADDITIONAL_INFO_MAX_CHARS = ADDITIONAL_INFO_MAX_CHARS;
  protected readonly EC1_NAME_MAX_CHARS = EC1_NAME_MAX_CHARS;
  protected readonly EC1_COUNTRY_MAX_CHARS = EC1_COUNTRY_MAX_CHARS;
  protected readonly EC1_PHONE_NUMBER_MAX_CHARS = EC1_PHONE_NUMBER_MAX_CHARS;
  protected readonly EC1_EMAIL_MAX_CHARS = EC1_EMAIL_MAX_CHARS;
  protected readonly EC2_PHONE_NUMBER_MAX_CHARS = EC2_PHONE_NUMBER_MAX_CHARS;
  protected readonly EC2_EMAIL_MAX_CHARS = EC2_EMAIL_MAX_CHARS;
  protected readonly EC2_COUNTRY_MAX_CHARS = EC2_COUNTRY_MAX_CHARS;
  protected readonly EC2_NAME_MAX_CHARS = EC2_NAME_MAX_CHARS;
}
