import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Order} from "../model/order";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http : HttpClient) { }

  public findById(orderId: number): Observable<Order> {
    return this.http.get<Order>(environment.apiUrlPrefix + 'order/' + orderId);
  }

  public createInternalOrder(order: Order): Observable<HttpResponse<Order>> {
    return this.http.post<Order>(environment.apiUrlPrefix + 'internalOrder', order, {observe: 'response'});
  }

  public updateOrder(orderId: number, order: Order) {
    const body = {
      orderId: orderId,
      order: order
    }

    return this.http.patch<Order>(environment.apiUrlPrefix + 'order', body, {observe: 'response'});
  }

  public deleteOrder(orderId: number) {
    return this.http.delete(environment.apiUrlPrefix + 'order/' + orderId);
  }

  public bulkUpload(orderId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('orderId', orderId.toString());

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.http.post<Order>(environment.apiUrlPrefix + 'bulkUpload', formData, {
      headers: headers,
      observe: 'response'
    });
  }
}
