<div style="width: 70%; margin-right: auto; margin-left: auto">
  <div class="mb-3">
    <label for="imeis" class="form-label">IMEI(s)*</label>
    <span style="color: gray; font-size: 12px; padding-left: 5px;">comma or line-separated list</span>
    <textarea [cdkTextareaAutosize]="true" id="imeis" class="form-control" [(ngModel)]="imeisString"></textarea>
  </div>
  <div class="mb-3">
    <button class="btn btn-primary" type="button"
            (click)="validateImeis()"
            [disabled]="validationDisabled">validate imeis</button>
  </div>
  <div class="alert alert-success" role="alert" *ngIf="imeiValidationResult">IMEIs are valid and ready to be activated.</div>
  <div class="alert alert-danger" role="alert" *ngIf="hasErrorMessage()">
    <div>{{malformedImeiMessage}}</div>
    <div>{{notFoundImeiMessage}}</div>
    <div>{{deactivateImeiMessage}}</div>
    <div>{{activeOnHubMessage}}</div>
  </div>

  <table *ngIf="imeiResults.length > 0">
    <thead>
      <tr>
        <th>IMEI</th>
        <th>Status</th>
        <th>Garmin Network Status</th>
        <th>Garmin User Id</th>
        <th>Hub Device Name</th>
        <th>Hub Team Id</th>
        <th>Rejection Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let response of imeiResults">
        <td>{{response.imeiInfo.imei}}</td>
        <td>{{response.imeiInfo.imeiStatus}}</td>
        <td>{{response.imeiInfo.garminNetworkStatus}}</td>
        <td>{{response.imeiInfo.garminUserId}}</td>
        <td>{{response.imeiInfo.hubDeviceName}}</td>
        <td>{{response.imeiInfo.teamId}}</td>
        <td>{{response.rejectionReason}}</td>
      </tr>
    </tbody>
  </table>
</div>
