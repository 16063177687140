import {FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {OrderTypes} from "./OrderTypeConstants";
import {
  HARDWARE_MODEL_MAX_CHARS,
  PHONE_NUMBER_MAX_CHARS,
  PLAN_TYPE_MAX_CHARS
} from "../model/order";
import {
  ADDRESS2_MAX_CHARS,
  ADDRESS_MAX_CHARS, CITIZENSHIP_MAX_CHARS,
  CITY_MAX_CHARS, COUNTRY_MAX_CHARS,
  STATE_MAX_CHARS, ZIP_CODE_MAX_CHARS
} from "../model/address";
import {
  ADDITIONAL_INFO_MAX_CHARS,
  EC1_COUNTRY_MAX_CHARS,
  EC1_EMAIL_MAX_CHARS,
  EC1_NAME_MAX_CHARS,
  EC1_PHONE_NUMBER_MAX_CHARS,
  EC2_COUNTRY_MAX_CHARS, EC2_EMAIL_MAX_CHARS,
  EC2_NAME_MAX_CHARS,
  EC2_PHONE_NUMBER_MAX_CHARS,
  EC_EMAIL_MAX_CHARS,
  EC_FIRST_NAME_MAX_CHARS,
  EC_LAST_NAME_MAX_CHARS,
  EC_PHONE_NUMBER_MAX_CHARS
} from "../model/emergencyContact";

export const NOT_EMPTY_VALIDATORS = [Validators.required, Validators.minLength(1)];

export function processFormSelectionChange(formSelection: string, orderForm: FormGroup, isEdit: boolean | null) {
  switch (formSelection) {
    case OrderTypes.NEW:
      removeValidation('imeis', orderForm);
      addValidation('hardwareModel', getValidators(HARDWARE_MODEL_MAX_CHARS), orderForm);
      addValidation('hardwareQuantity', NOT_EMPTY_VALIDATORS, orderForm);
      addValidation('servicePlan', getValidators(PLAN_TYPE_MAX_CHARS), orderForm);
      addValidation('phoneNumber', getValidators(PHONE_NUMBER_MAX_CHARS), orderForm);
      addValidation('streetAddress', getValidators(ADDRESS_MAX_CHARS), orderForm);
      addValidation('streetAddress2', [Validators.maxLength(ADDRESS2_MAX_CHARS)], orderForm);
      addValidation('city', getValidators(CITY_MAX_CHARS), orderForm);
      addValidation('state', getValidators(STATE_MAX_CHARS), orderForm);
      addValidation('postalCode', getValidators(ZIP_CODE_MAX_CHARS), orderForm);
      addValidation('country', getValidators(COUNTRY_MAX_CHARS), orderForm);
      break;
    case OrderTypes.EXISTING:
      removeValidation('hardwareModel', orderForm);
      removeValidation('hardwareQuantity', orderForm);
      removeValidation('phoneNumber', orderForm);
      removeValidation('streetAddress', orderForm);
      removeValidation('streetAddress2', orderForm);
      removeValidation('city', orderForm);
      removeValidation('state', orderForm);
      removeValidation('postalCode', orderForm);
      removeValidation('country', orderForm);
      addValidation('servicePlan', [Validators.required], orderForm);
      if (!isEdit) {
        addValidation('imeis', NOT_EMPTY_VALIDATORS, orderForm);
        addValidation('acknowledgeTransfer', [Validators.requiredTrue], orderForm);
      }
      break;
    default:
      removeValidation('imeis', orderForm);
      removeValidation('hardwareModel', orderForm);
      removeValidation('hardwareQuantity', orderForm);
      removeValidation('servicePlan', orderForm);
      removeValidation('phoneNumber', orderForm);
      removeValidation('streetAddress', orderForm);
      removeValidation('city', orderForm);
      removeValidation('state', orderForm);
      removeValidation('postalCode', orderForm);
      removeValidation('country', orderForm);

  }

  orderForm.updateValueAndValidity();
}

export function processEmergencyContactChange(newContacts: boolean, orderForm: FormGroup) {
  if (newContacts) {
    addValidation('ecFirstName', getValidators(EC_FIRST_NAME_MAX_CHARS), orderForm);
    addValidation('ecLastName', getValidators(EC_LAST_NAME_MAX_CHARS), orderForm);
    addValidation('ecStreetAddress', getValidators(ADDRESS_MAX_CHARS), orderForm);
    addValidation('ecCity', getValidators(CITY_MAX_CHARS), orderForm);
    addValidation('ecState', getValidators(STATE_MAX_CHARS), orderForm);
    addValidation('ecPostalCode', getValidators(ZIP_CODE_MAX_CHARS), orderForm);
    addValidation('ecCountry', getValidators(COUNTRY_MAX_CHARS), orderForm);
    addValidation('ecCitizenship', getValidators(CITIZENSHIP_MAX_CHARS), orderForm);
    addValidation('ecEmail', getValidators(EC_EMAIL_MAX_CHARS), orderForm);
    addValidation('ecPhone', getValidators(EC_PHONE_NUMBER_MAX_CHARS), orderForm);
    addValidation('ecEmail', getValidators(EC_EMAIL_MAX_CHARS), orderForm);
    addValidation('ecAdditionalInfo', [Validators.maxLength(ADDITIONAL_INFO_MAX_CHARS)], orderForm);
    addValidation('ec1Name', getValidators(EC1_NAME_MAX_CHARS), orderForm);
    addValidation('ec1Country', getValidators(EC1_COUNTRY_MAX_CHARS), orderForm);
    addValidation('ec1Phone', getValidators(EC1_PHONE_NUMBER_MAX_CHARS), orderForm);
    addValidation('ec1Email', getValidators(EC1_EMAIL_MAX_CHARS), orderForm);
    addValidation('ec2Name', getValidators(EC2_NAME_MAX_CHARS), orderForm);
    addValidation('ec2Country', getValidators(EC2_COUNTRY_MAX_CHARS), orderForm);
    addValidation('ec2Phone', getValidators(EC2_PHONE_NUMBER_MAX_CHARS), orderForm);
    addValidation('ec2Email', getValidators(EC2_EMAIL_MAX_CHARS), orderForm);
  } else {
    removeValidation('ecFirstName', orderForm);
    removeValidation('ecLastName', orderForm);
    removeValidation('ecStreetAddress', orderForm);
    removeValidation('ecCity', orderForm);
    removeValidation('ecState', orderForm);
    removeValidation('ecPostalCode', orderForm);
    removeValidation('ecCountry', orderForm);
    removeValidation('ecCitizenship', orderForm);
    removeValidation('ecEmail', orderForm);
    removeValidation('ecPhone', orderForm);
    removeValidation('ecAdditionalInfo', orderForm);
    removeValidation('ec1Name', orderForm);
    removeValidation('ec1Country', orderForm);
    removeValidation('ec1Phone', orderForm);
    removeValidation('ec1Email', orderForm);
    removeValidation('ec2Name', orderForm);
    removeValidation('ec2Country', orderForm);
    removeValidation('ec2Phone', orderForm);
    removeValidation('ec2Email', orderForm);
  }

  orderForm.updateValueAndValidity();
}

export function removeValidation(formControlName: string, orderForm: FormGroup) {
  orderForm.controls[formControlName]?.clearValidators();
  orderForm.controls[formControlName]?.setErrors(null);
  orderForm.controls[formControlName]?.markAsUntouched();
  orderForm.controls[formControlName]?.markAsPristine();
  orderForm.controls[formControlName]?.updateValueAndValidity();
}

export function addValidation(formControlName: string, validators: ValidatorFn[], orderForm: FormGroup) {
  orderForm.controls[formControlName]?.addValidators(validators);
  orderForm.controls[formControlName]?.updateValueAndValidity();
}

export function getValidators(maxLength : number) : ValidatorFn[]  {
  return [Validators.required, Validators.minLength(1), Validators.maxLength(maxLength)];
}
