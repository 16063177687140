import {Component, inject, Input} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {DeviceService} from "../../service/device.service";
import {DeleteOrderComponent} from "../delete-order/delete-order.component";

@Component({
  selector: 'app-delete-imei',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent
  ],
  template: `
    <mat-dialog-content>
      <p>
        Are you sure you want to delete selected IMEI(s)?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-button (click)="delete()">Yes</button>
    </mat-dialog-actions>
  `,
  styleUrl: './delete-imei.component.css'
})
export class DeleteImeiComponent {
  @Input() imeis!: number[];
  deviceService = inject(DeviceService);

  constructor(public dialogRef: MatDialogRef<DeleteOrderComponent>) {
  }

  public setImeis(imeis: number[]) {
    this.imeis = imeis;
  }

  delete() {
    this.deviceService.deleteDevices(this.imeis).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
