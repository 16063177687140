import {Component, inject} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {COMPANY_NAME_MAX_CHARS, Order} from "../../model/order";
import {OrderService} from "../../service/order.service";
import {NgIf} from "@angular/common";
import {catchError, of, tap} from "rxjs";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-internal-order-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    MatFormField,
    MatSelect,
    MatOption
  ],
  templateUrl: './internal-order-form.component.html',
  styleUrl: './internal-order-form.component.css'
})
export class InternalOrderFormComponent {

  authService: AuthService = inject(AuthService);
  orderService: OrderService = inject(OrderService);

  // flags for managing display
  formSubmitted: boolean = false;
  processingSubmit: boolean = false;
  postSubmitError: boolean = false;

  orderForm: FormGroup = this.createForm();
  orderNumber: number | null = null;

  userEmail: string | null = null;

  constructor(private formBuilder: FormBuilder) {
    this.authService.user$.subscribe(user => {
      this.userEmail = user!.email!;
    });
  }

  submit() {
    this.processingSubmit = true;
    this.postSubmitError = false;
    const order = new Order();

    order.orderType = this.orderType?.value;
    order.companyName = this.companyName?.value;
    order.creatorEmail = this.userEmail;

    this.orderService.createInternalOrder(order).pipe(
      tap((response) => {
        this.orderNumber = response.body!.orderId;
      }), catchError(err => {
        console.log(err);
        return of(false);
      })
    ).subscribe(success => {
      this.processingSubmit = false;
      if (!success) {
        this.postSubmitError = true;
      } else {
        this.formSubmitted = true;
      }
    });
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      companyName: [null, [Validators.required, Validators.maxLength(COMPANY_NAME_MAX_CHARS)]],
      orderType: [null, [Validators.required]]
    });
  }

  refreshForm(){
    this.formSubmitted = false;
    this.processingSubmit = false;
    this.postSubmitError = false;
    this.orderNumber = null;
    this.orderForm = this.createForm();
  }

  get companyName() {
    return this.orderForm.get('companyName');
  }

  get orderType() {
    return this.orderForm.get('orderType');
  }

  protected readonly COMPANY_NAME_MAX_CHARS = COMPANY_NAME_MAX_CHARS;
}
