<div>
  <h2 mat-dialog-title style="display: inline">
    Order #{{order.orderId}} {{order.companyName}}
  </h2>
  <mat-icon aria-hidden="false" aria-label="Edit Customer Name" class="clickable edit-customer-name"
            matTooltip="edit customer name" (click)="editCustomerName()">edit
  </mat-icon>
</div>
<mat-dialog-content style="color: #111111">
  <div style="margin-left: 15px;">
    <div>Order type: {{order.orderType}}</div>
    <div>Created by: {{order.creatorEmail}}</div>
    <div>Created on: {{order.createdDateTime}}</div>
  </div>
  <div style="margin: 15px;">
    <app-activation-form [order]="order" #activationForm></app-activation-form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
