import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, map, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  constructor(private http: HttpClient) { }

  public startActivation(imeiIds: number[]) {
    const body = {
      imeiIds: imeiIds
    }

    return this.http.patch(environment.apiUrlPrefix + 'device/activate', body, {responseType: 'text'})
      .pipe(
        map((response) => {
          console.log(response);
          return true;
        }),
        catchError(err => {
          console.log(err);
          return of(false);
        })
      );
  }

  public startDeactivation(imeiIds: number[]) {
    const body = {
      imeiIds: imeiIds
    }

    return this.http.patch(environment.apiUrlPrefix + 'device/deactivate', body, {responseType: 'text'})
      .pipe(
        map((response) => {
          console.log(response);
          return true;
        }),
        catchError(err => {
          console.log(err);
          return of(false);
        })
      );
  }
}
