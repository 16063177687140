import { Routes } from '@angular/router';
import {AuthGuard} from "@auth0/auth0-angular";
import {everywhereGuard} from "./guards/everywhere.guard";
import {customerGuard} from "./guards/customer.guard";
import {ValidateImeiComponent} from "./actions/validate-imei/validate-imei.component";
import {HomeComponent} from "./views/home/home.component";
import {OrdersPageComponent} from "./views/orders-page/orders-page.component";
import {OrderFormComponent} from "./actions/order-form/order-form.component";
import {CreateCustomerComponent} from "./actions/create-customer/create-customer.component";
import {
  InternalOrderFormComponent
} from "./actions/internal-order-form/internal-order-form.component";

const routeConfig: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: "full"
  },
  {
    path: 'order-form',
    component: OrderFormComponent,
    title: 'Order Form',
    canActivate: [AuthGuard, customerGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Home',
    canActivate: [AuthGuard, everywhereGuard]
  },
  {
    path: 'validate',
    component: ValidateImeiComponent,
    title: 'Validate',
    canActivate: [AuthGuard, everywhereGuard]
  },
  {
    path: 'orders',
    component: OrdersPageComponent,
    title: 'Orders',
    canActivate: [AuthGuard, everywhereGuard]
  },
  {
    path: 'create-customer',
    component: CreateCustomerComponent,
    title: 'Create Customer',
    canActivate: [AuthGuard, everywhereGuard]
  },
  {
    path: 'internal-order-form',
    component: InternalOrderFormComponent,
    title: 'Internal Order Form',
    canActivate: [AuthGuard, everywhereGuard]
  }
];

export default routeConfig;
