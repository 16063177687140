import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import {AuthHttpInterceptor} from "@auth0/auth0-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {NavbarComponent} from "./views/navbar/navbar.component";

@Component({
  selector: 'app-root',
  standalone: true,
  template: `
    <main>
      <section>
        <app-navbar></app-navbar>
        <!-- TODO add header/footer -->
        <div style="padding-top: 25px; padding-bottom: 25px;">
          <router-outlet></router-outlet>
        </div>
      </section>
    </main>
  `,
  imports: [
    RouterModule,
    LoginComponent,
    LogoutComponent,
    NavbarComponent
  ],
  styleUrls: ['./app.component.css'],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    }
  ]
})
export class AppComponent {}
