import {Order} from "../model/order";
import {Address} from "../model/address";
import {EmergencyContact} from "../model/emergencyContact";
import {OrderFormComponent} from "../actions/order-form/order-form.component";
import {ImeiInfo} from "../model/imeiInfo";
import {EditOrderFormComponent} from "../actions/edit-order-form/edit-order-form.component";
import {HardwareModels, HardwareColors} from "./HardwareModelConstants";
import {OrderTypes} from "./OrderTypeConstants";

// id value doesn't matter because the server generates ids, but it is required in the request because
// the id is stored as a primitive in the Java application.
const DEFAULT_ID = 0;

export function convert(orderFormComponent: OrderFormComponent | EditOrderFormComponent, orderType: string, emailAddress: string): Order {
  let order: Order = new Order();
  order.creatorEmail = emailAddress;
  order.firstName = orderFormComponent.firstName?.value;
  order.lastName = orderFormComponent.lastName?.value;
  order.companyName = orderFormComponent.companyName?.value;
  if (orderFormComponent instanceof OrderFormComponent) {
    order.updateMainPoc = orderFormComponent.updateMainPoc?.value;
  }
  order.teamName = orderFormComponent.teamName?.value;
  order.phoneNumber = orderFormComponent.phoneNumber?.value;

  let address: Address = new Address(
    DEFAULT_ID,
    orderFormComponent.streetAddress?.value,
    orderFormComponent.streetAddress2?.value,
    orderFormComponent.city?.value,
    orderFormComponent.state?.value,
    orderFormComponent.postalCode?.value,
    orderFormComponent.country?.value,
    null
  );

  let emergencyContactAddress = new Address(
    DEFAULT_ID,
    orderFormComponent.ecStreetAddress?.value,
    null,
    orderFormComponent.ecCity?.value,
    orderFormComponent.ecState?.value,
    orderFormComponent.ecPostalCode?.value,
    orderFormComponent.ecCountry?.value,
    orderFormComponent.ecCitizenship?.value,
  );

  let emergencyContact = new EmergencyContact(
    DEFAULT_ID,
    emergencyContactAddress,
    orderFormComponent.ecFirstName?.value,
    orderFormComponent.ecLastName?.value,
    orderFormComponent.ecPhone?.value,
    orderFormComponent.ecEmail?.value,
    orderFormComponent.ecAdditionalInfo?.value,
    orderFormComponent.ec1Name?.value,
    orderFormComponent.ec1Country?.value,
    orderFormComponent.ec1Phone?.value,
    orderFormComponent.ec1Email?.value,
    orderFormComponent.ec2Name?.value,
    orderFormComponent.ec2Country?.value,
    orderFormComponent.ec2Phone?.value,
    orderFormComponent.ec2Email?.value,
  );

  order.orderType = orderType;
  switch (orderType) {
    case OrderTypes.NEW:
      order.hardwareModel = orderFormComponent.hardwareModel?.value;
      // reassigning for inreachmini2 because of color
      if (order.hardwareModel === HardwareModels.INREACHMINI2_ORANGE) {
        order.hardwareModel = HardwareModels.INREACHMINI2;
        order.hardwareColor = HardwareColors.ORANGE;
      } else if (order.hardwareModel === HardwareModels.INREACHMINI2_BLACK) {
        order.hardwareModel = HardwareModels.INREACHMINI2;
        order.hardwareColor = HardwareColors.BLACK;
      } else if (order.hardwareModel === HardwareModels.INREACHMINI2 && orderFormComponent instanceof EditOrderFormComponent) {
        order.hardwareColor = orderFormComponent.hardwareColor?.value;
      }

      order.hardwareQuantity = orderFormComponent.hardwareQuantity?.value;
      order.planType = orderFormComponent.servicePlan?.value;
      order.shippingAddress = address;
      order.imeiInfo = [];
      break;
    case OrderTypes.EXISTING:
      order.planType = orderFormComponent.servicePlan?.value;
      if (orderFormComponent instanceof OrderFormComponent) {
        order.imeiInfo = convertImeis(orderFormComponent.imeis?.value);
      }
      break;
    default:
      console.log("ERROR - invalid orderType: " + orderType);
  }

  order.updateEmergencyContacts = orderFormComponent.updateEmergencyContact?.value;

  if (order.updateEmergencyContacts) {
    order.emergencyContact = emergencyContact;
  }

  return order;
}

function convertImeis(imeis:string | null): ImeiInfo[] {
  let convertedImeis: ImeiInfo[] = [];

  if (!imeis) {
    return convertedImeis;
  }

  let imeisArray: string[] = imeis.split(/[\n\s\t,]/).filter((imei) => imei && imei.length > 0);
  imeisArray.forEach(imei => {
    convertedImeis.push(new ImeiInfo(imei));
  });

  return convertedImeis;
}
