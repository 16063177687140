import {
  Component,
  inject,
  Input,
  OnChanges,
} from '@angular/core';
import {Order} from "../../model/order";
import {
  FormArray,
  FormBuilder,
  FormControl, FormControlState, FormGroup,
  ReactiveFormsModule
} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {ImeiInfo} from "../../model/imeiInfo";
import {catchError, of, tap} from "rxjs";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBar} from "@angular/material/snack-bar";
import {OrderService} from "../../service/order.service";
import {ActivationService} from "../../service/activation.service";
import {MatIcon} from "@angular/material/icon";
import {ReportComponent} from "../report/report.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteImeiComponent} from "../delete-imei/delete-imei.component";

const JSON_SPACING = 2;
const SNACKBAR_DURATION_MS = 3000;
const REFRESH_INTERVAL_MS = 5000;

class DeviceFormControl extends FormControl {
  imei: string;

  constructor(formControlState: FormControlState<string>, imei: string) {
    super(formControlState);
    this.imei = imei;
  }
}

@Component({
  selector: 'app-activation-form',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    NgIf,
    CdkTextareaAutosize,
    CdkCopyToClipboard,
    MatTooltipModule,
    MatIcon,
    ReportComponent
  ],
  templateUrl: './activation-form.component.html',
  styleUrl: './activation-form.component.css'
})
export class ActivationFormComponent implements OnChanges {
  orderService = inject(OrderService);
  activationService = inject(ActivationService);

  @Input() order!: Order;
  activationForm: FormGroup | null = null;

  // additional flags for displaying messages
  displayActivationErrorMessage = false;
  displayDeactivationErrorMessage = false;
  imeiRefreshInProgress = false;
  imeiRefreshFailed = false;
  uploadingFile = false;
  uploadFileFailureMessage: string | null = null;
  refreshInterval: number;

  ngOnChanges() {
    if (this.order) {
      this.activationForm = this.initializeActivationForm();
      this.activationForm.get('selectAll')?.valueChanges.subscribe(change => {
        if (change) {
          this.getDevices().forEach(control => {
            if (!control.disabled) {
              control.setValue(true);
            }
          })
        } else {
          this.getDevices().forEach(control => {
            control.setValue(false);
          })
        }
      });
    }
  }

  constructor(public formBuilder: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    this.refreshInterval = setInterval(() => this.refreshImeis(), REFRESH_INTERVAL_MS);
  }

  deleteDevices() {
    let imeiInfoIds = <number[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value)
      .map(imeiInfo => imeiInfo.imeiInfoId);

    const dialogRef = this.dialog.open(DeleteImeiComponent);
    dialogRef.componentInstance.setImeis(imeiInfoIds);

    dialogRef.afterClosed().subscribe(() => {
      this.orderService.findById(this.order.orderId).subscribe(order => {
        this.order = order;
        this.ngOnChanges();
      });
    });
  }

  activateDevices() {
    let imeiInfoIds = <number[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value)
      .map(imeiInfo => imeiInfo.imeiInfoId);

    this.activationService.startActivation(imeiInfoIds).subscribe(success => {
      if (success) {
        this.orderService.findById(this.order.orderId).subscribe(order => {
          this.displayActivationErrorMessage = false;
          this.order = order;
          this.ngOnChanges();
        });
      } else {
        this.displayActivationErrorMessage = true;
      }
    });
  }

  deactivateDevices() {
    let imeiInfoIds = <number[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value)
      .map(imeiInfo => imeiInfo.imeiInfoId);

    this.activationService.startDeactivation(imeiInfoIds).subscribe(success => {
      if (success) {
        this.orderService.findById(this.order.orderId).subscribe(order => {
          this.displayDeactivationErrorMessage = false;
          this.order = order;
          this.ngOnChanges();
        });
      } else {
        this.displayDeactivationErrorMessage = true;
      }
    });
  }

  initializeActivationForm() {
    let controls:any = {
      devices: new FormArray([]),
      selectAll: new FormControl(false)
    }

    let activationForm = this.formBuilder.group(controls);

    this.order?.imeiInfo?.forEach(imeiInfo => {
      let checkboxDisabled = !this.canSelectDevice(imeiInfo);
      (<FormArray>activationForm.get('devices')).push(new DeviceFormControl({value:'', disabled: checkboxDisabled}, imeiInfo.imei));
    });

    return activationForm;
  }

  refreshActivationForm() {
    let devices: DeviceFormControl[] = this.getDevices() as DeviceFormControl[];
    this.order?.imeiInfo?.forEach(imeiInfo => {
      let matchedDevice = devices.filter(device => device.imei === imeiInfo.imei).pop();

      if (matchedDevice) {
        if (this.canSelectDevice(imeiInfo)) {
          matchedDevice.enable();
        } else {
          matchedDevice.setValue('');
          matchedDevice.disable();
        }
      } else if (!matchedDevice) {
        let checkboxDisabled = !this.canSelectDevice(imeiInfo);
        (<FormArray>this.activationForm?.get('devices')).push(new DeviceFormControl({value:'', disabled: checkboxDisabled}, imeiInfo.imei));
      }
    });
  }

  canSelectDevice(imeiInfo: ImeiInfo) {
    return imeiInfo.imeiStatus === 'SANITIZE' || !imeiInfo.activationStatus || (imeiInfo.activationStatus.startsWith('FAILED_'));
  }

  getDevices() {
    return (<FormArray>this.activationForm?.get('devices')).controls;
  }

  devicesSelected() {
    return this.getDevices().some(ctrl => ctrl.value === true);
  }

  canActivateDevices() {
    if (this.activationForm?.invalid || !this.devicesSelected()) {
      return false;
    }

    let imeis = <ImeiInfo[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value);

    return imeis.every((imei) => {
      return imei.imeiStatus === 'READY' || imei.imeiStatus === 'REACTIVATE';
    });
  }

  canRevalidateDevices() {
    if (this.activationForm?.invalid || !this.devicesSelected()) {
      return false;
    }

    let imeis = <ImeiInfo[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value);

    return imeis.every((imei) => {
      return imei.imeiStatus === 'SANITIZE' || imei.imeiStatus === 'ERROR' || imei.imeiStatus === 'DEACTIVATE';
    });
  }

  canDeactivateDevices() {
    if (this.activationForm?.invalid || !this.devicesSelected()) {
      return false;
    }

    let imeis = <ImeiInfo[]>this.order.imeiInfo!
      .filter((imeiInfo, index) => imeiInfo.imeiInfoId && this.getDevices()[index].value);

    return imeis.every((imei) => {
      return imei.activationType === 'DEACTIVATION';
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    this.uploadingFile = true;
    this.uploadFileFailureMessage = null;
    this.orderService.bulkUpload(this.order.orderId, file).pipe(
      tap((response) => {
        if (response.body) {
          console.log("successfully uploaded file");
        }
      }),
      catchError((error) => {
        console.log(error);
        if (error.error && error.error instanceof Array) {
          this.uploadFileFailureMessage = error.error.join('\n');
        } else {
          this.uploadFileFailureMessage = "File upload failed for unexpected reason.";
        }

        return of(false);
      })
    ).subscribe((success) => {
      this.uploadingFile = false;
      if (success) {
        this.orderService.findById(this.order.orderId).subscribe(order => {
          this.order = order;
          this.ngOnChanges();
        });
      }
    });
  }

  refreshImeis() {
    if (!this.imeiRefreshInProgress) {
      this.imeiRefreshInProgress = true;

      this.orderService.findById(this.order.orderId).subscribe({
        next: (order) => this.handleOrderOnRefresh(order),
        error: (err) => this.handleRefreshFailed(err)
      });
    }
  }

  handleOrderOnRefresh(order: Order) {
    this.order = order;
    this.refreshActivationForm();
    this.imeiRefreshInProgress = false;
    this.imeiRefreshFailed = false;
  }

  handleRefreshFailed(err: any) {
    console.log(err);
    this.imeiRefreshFailed = true;
    this.imeiRefreshInProgress = false;
  }

  copyImeis(): string {
    if (this.order.imeiInfo) {
      return this.order.imeiInfo.map(imeiInfo => imeiInfo.imei).join('\n');
    }

    return '';
  }

  confirmImeisCopied() {
    this._snackBar.open('IMEIs copied to clipboard', undefined, {
      duration: SNACKBAR_DURATION_MS
    });
  }

  copyImeiInfoToClipboard(imeiInfo: ImeiInfo) {
    return JSON.stringify(imeiInfo, null, JSON_SPACING);
  }

  confirmImeiInfoCopied() {
    this._snackBar.open('IMEI Info copied to clipboard', undefined, {
      duration: SNACKBAR_DURATION_MS
    });
  }
}
