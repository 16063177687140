import {Component, inject} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DeviceService} from "../../service/device.service";
import {ImeiInfo} from "../../model/imeiInfo";
import {
  formatInvalidImeiStatuses,
  InvalidImeiMessages
} from "../../utils/ImeiInvalidStatusFormatter";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {NgForOf, NgIf} from "@angular/common";
import {ValidateImeiResponse} from "../../model/validateImeiResponse";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-validate-imei',
  standalone: true,
  imports: [
    FormsModule,
    CdkTextareaAutosize,
    NgIf,
    MatTooltip,
    NgForOf,
    ReactiveFormsModule
  ],
  templateUrl: './validate-imei.component.html',
  styleUrl: './validate-imei.component.css'
})
export class ValidateImeiComponent {
  deviceService = inject(DeviceService);

  imeisString: string | null = null;
  imeiResults: ValidateImeiResponse[] = [];

  validationDisabled = false;
  imeiValidationResult = false;

  // errors to display under IMEI input
  malformedImeiMessage: string | null = null;
  notFoundImeiMessage: string | null = null;
  deactivateImeiMessage: string | null = null;
  activeOnHubMessage: string | null = null;

  public validateImeis() {
    let imeisArray;
    try {
      imeisArray = this.imeisString?.split(/[\n\s\t,]/).filter((imei) => imei && imei.length > 0);
    } catch (e) {
      console.log(e);
      return;
    }

    // don't allow user to click validate button while request is being made
    this.validationDisabled = true;
    this.deviceService.validateImeis(imeisArray ? imeisArray : []).subscribe(responses => {
      this.imeiResults = responses;
      this.processImeiStatuses(responses.map(response => response.imeiInfo));
      this.validationDisabled = false;
    });
  }

  private processImeiStatuses(imeis: ImeiInfo[]) {
    let invalidImeiMessages: InvalidImeiMessages = formatInvalidImeiStatuses(imeis);
    this.malformedImeiMessage = invalidImeiMessages.malformedImeiMessage;
    this.notFoundImeiMessage = invalidImeiMessages.notFoundImeiMessage;
    this.deactivateImeiMessage = invalidImeiMessages.deactivateImeiMessage;
    this.activeOnHubMessage = invalidImeiMessages.activeOnHubMessage;
    this.imeiValidationResult = !this.hasErrorMessage();
  }

  hasErrorMessage() {
    return !!this.malformedImeiMessage || !!this.notFoundImeiMessage || !!this.deactivateImeiMessage || !!this.activeOnHubMessage;
  }
}
