<form [formGroup]="editOrderForm!">
  <section style="margin-bottom: 25px;">
    <h3>Customer Information</h3>
    <div class="row g-3">
      <span class="col-4">First Name</span>
      <input class="col-8" type="text" formControlName="firstName" [maxlength]="FIRST_NAME_MAX_CHARS" [ngClass]="{'inputRequired': firstName?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Last Name</span>
      <input class="col-8" type="text" formControlName="lastName" [maxlength]="LAST_NAME_MAX_CHARS" [ngClass]="{'inputRequired': lastName?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Email Address</span>
      <input class="col-8" type="text" [disabled]="!!order" [value]="order.creatorEmail">
    </div>
    <div class="row g-3">
      <span class="col-4">Phone Number</span>
      <input class="col-8" type="text" formControlName="phoneNumber" [maxlength]="PHONE_NUMBER_MAX_CHARS" [ngClass]="{'inputRequired': phoneNumber?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Company Name</span>
      <input class="col-8" type="text" formControlName="companyName" [maxlength]="COMPANY_NAME_MAX_CHARS" [ngClass]="{'inputRequired': companyName?.invalid}">
    </div>
  </section>
  <section style="margin-bottom: 25px;">
    <h3>Order Details</h3>
    <div class="row g-3">
      <span class="col-4">Order Type</span>
      <input class="col-8" type="text" [disabled]="!!order" [value]="orderTypeDisplayName(order.orderType)">
    </div>
    <div class="row g-3">
      <span class="col-4">Team Name</span>
      <input class="col-8" type="text" formControlName="teamName" [maxlength]="TEAM_NAME_MAX_CHARS" [ngClass]="{'inputRequired': teamName?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Service Plan</span>
      <select class="col-8" formControlName="servicePlan" *ngIf="order">
        <option *ngFor="let planType of planTypes" [selected]="planType === order.planType">
          {{planType}}
        </option>
      </select>
    </div>
    <div *ngIf="order.orderType === NEW">
      <div class="row g-3">
        <span class="col-4">Hardware Model</span>
        <select class="col-8" formControlName="hardwareModel" *ngIf="order">
          <option *ngFor="let hardwareType of hardwareTypes" [selected]="hardwareType === order.hardwareModel">
            {{hardwareType}}
          </option>
        </select>
      </div>
      <div class="row g-3" *ngIf="hardwareModel?.value === INREACHMINI2">
        <span class="col-4">Hardware Color</span>
        <select class="col-8" formControlName="hardwareColor">
          <option *ngFor="let hardwareColor of hardwareColors" [selected]="hardwareColor === order.hardwareColor">
            {{hardwareColor}}
          </option>
        </select>
      </div>
      <div class="row g-3">
        <span class="col-4">Hardware Quantity</span>
        <input class="col-8" type="text" formControlName="hardwareQuantity" [ngClass]="{'inputRequired': hardwareQuantity?.invalid}">
      </div>
    </div>
  </section>
  <section *ngIf="order.orderType === NEW" style="margin-bottom: 25px;">
    <h3>Shipping Address</h3>
    <div class="row g-3">
      <span class="col-4">Street Address</span>
      <input class="col-8" type="text" formControlName="streetAddress" [maxlength]="ADDRESS_MAX_CHARS" [ngClass]="{'inputRequired': streetAddress?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Address Line 2</span>
      <input class="col-8" type="text" formControlName="streetAddress2" [maxlength]="ADDRESS2_MAX_CHARS" [ngClass]="{'inputRequired': streetAddress2?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">City</span>
      <input class="col-8" type="text" formControlName="city" [maxlength]="CITY_MAX_CHARS" [ngClass]="{'inputRequired': city?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">State</span>
      <input class="col-8" type="text" formControlName="state" [maxlength]="STATE_MAX_CHARS" [ngClass]="{'inputRequired': state?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Zip Code</span>
      <input class="col-8" type="text" formControlName="postalCode" [maxlength]="ZIP_CODE_MAX_CHARS" [ngClass]="{'inputRequired': postalCode?.invalid}">
    </div>
    <div class="row g-3">
      <span class="col-4">Country</span>
      <input class="col-8" type="text" formControlName="country" [maxlength]="COUNTRY_MAX_CHARS" [ngClass]="{'inputRequired': country?.invalid}">
    </div>
  </section>
  <section>
    <h3>Emergency Contacts</h3>
    <select id="updateEmergencyContact" formControlName="updateEmergencyContact" style="width:100%;margin-bottom:10px;">
      <option [ngValue]="false">Use my Existing Emergency Contacts</option>
      <option [ngValue]="true">New Emergency Contacts</option>
    </select>
    <div *ngIf="updateEmergencyContact?.value" style="margin-bottom: 25px;">
      <div class="row g-3">
        <span class="col-4">First Name</span>
        <input class="col-8" type="text" formControlName="ecFirstName" [maxlength]="EC_FIRST_NAME_MAX_CHARS" [ngClass]="{'inputRequired': ecFirstName?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Last Name</span>
        <input class="col-8" type="text" formControlName="ecLastName" [maxlength]="EC_LAST_NAME_MAX_CHARS" [ngClass]="{'inputRequired': ecLastName?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Address</span>
        <input class="col-8" type="text" formControlName="ecStreetAddress" [maxlength]="ADDRESS_MAX_CHARS" [ngClass]="{'inputRequired': ecStreetAddress?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">City</span>
        <input class="col-8" type="text" formControlName="ecCity" [maxlength]="CITY_MAX_CHARS" [ngClass]="{'inputRequired': ecCity?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">State</span>
        <input class="col-8" type="text" formControlName="ecState" [maxlength]="STATE_MAX_CHARS" [ngClass]="{'inputRequired': ecState?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Zip Code</span>
        <input class="col-8" type="text" formControlName="ecPostalCode" [maxlength]="ZIP_CODE_MAX_CHARS" [ngClass]="{'inputRequired': ecPostalCode?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Country</span>
        <input class="col-8" type="text" formControlName="ecCountry" [maxlength]="COUNTRY_MAX_CHARS" [ngClass]="{'inputRequired': ecCountry?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Email</span>
        <input class="col-8" type="text" formControlName="ecEmail" [maxlength]="EC_EMAIL_MAX_CHARS" [ngClass]="{'inputRequired': ecEmail?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Phone</span>
        <input class="col-8" type="text" formControlName="ecPhone" [maxlength]="EC_PHONE_NUMBER_MAX_CHARS" [ngClass]="{'inputRequired': ecPhone?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Citizenship</span>
        <input class="col-8" type="text" formControlName="ecCitizenship" [maxlength]="CITIZENSHIP_MAX_CHARS" [ngClass]="{'inputRequired': ecCitizenship?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Additional Info</span>
        <input class="col-8" type="text" formControlName="ecAdditionalInfo" [maxlength]="ADDITIONAL_INFO_MAX_CHARS" [ngClass]="{'inputRequired': ecAdditionalInfo?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 1 Name</span>
        <input class="col-8" type="text" formControlName="ec1Name" [maxlength]="EC1_NAME_MAX_CHARS" [ngClass]="{'inputRequired': ec1Name?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 1 Country</span>
        <input class="col-8" type="text" formControlName="ec1Country" [maxlength]="EC1_COUNTRY_MAX_CHARS" [ngClass]="{'inputRequired': ec1Country?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 1 Phone</span>
        <input class="col-8" type="text" formControlName="ec1Phone" [maxlength]="EC1_PHONE_NUMBER_MAX_CHARS" [ngClass]="{'inputRequired': ec1Phone?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 1 Email</span>
        <input class="col-8" type="text" formControlName="ec1Email" [maxlength]="EC1_EMAIL_MAX_CHARS" [ngClass]="{'inputRequired': ec1Email?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 2 Name</span>
        <input class="col-8" type="text" formControlName="ec2Name" [maxlength]="EC2_NAME_MAX_CHARS" [ngClass]="{'inputRequired': ec2Name?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 2 Country</span>
        <input class="col-8" type="text" formControlName="ec2Country" [maxlength]="EC2_COUNTRY_MAX_CHARS" [ngClass]="{'inputRequired': ec2Country?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 2 Phone</span>
        <input class="col-8" type="text" formControlName="ec2Phone" [maxlength]="EC2_PHONE_NUMBER_MAX_CHARS" [ngClass]="{'inputRequired': ec2Phone?.invalid}">
      </div>
      <div class="row g-3">
        <span class="col-4">Contact 2 Email</span>
        <input class="col-8" type="text" formControlName="ec2Email" [maxlength]="EC2_EMAIL_MAX_CHARS" [ngClass]="{'inputRequired': ec2Email?.invalid}">
      </div>
    </div>
  </section>
</form>
