import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http : HttpClient) { }

  generateImeiStatusReport(imeiInfoIds: number[]) {
    return this.generateReport(imeiInfoIds, 'report/imei');
  }

  generateBlxReport(imeiInfoIds: number[]) {
    return this.generateReport(imeiInfoIds, 'report/blx');
  }

  generateDeactivationReport(orderId: number) {
    const options: Object = {
      responseType: 'text'
    }

    return this.http.get<string>(environment.apiUrlPrefix + 'report/deactivation/' + orderId, options);
  }

  private generateReport(imeiInfoIds: number[], reportPath: string) {
    const options: Object = {
      responseType: 'text'
    }

    return this.http.post<string>(environment.apiUrlPrefix + reportPath, {
      imeiInfoIds: imeiInfoIds
    }, options);
  }
}
