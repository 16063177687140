import {provideRouter, withHashLocation} from "@angular/router";

import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {importProvidersFrom} from "@angular/core";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import routeConfig from './app/app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {authConfig} from "./app/auth/auth.config";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routeConfig, withHashLocation()),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      AuthModule.forRoot(authConfig)
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    }, provideAnimationsAsync()
  ]
})
