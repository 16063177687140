<nav class="top-nav">
  <ul class="nav-list">
    <li><img src="assets/favicon.ico" alt="logo" aria-hidden="true"/></li>
    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/">Home</a></li>
    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/validate">Validate</a></li>
    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/orders">Orders</a></li>
    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/create-customer">Create Customer</a></li>
<!--    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/order-form">Order Form</a></li>-->
    <li *ngIf="isEverywhereUser$ | async"><a routerLink="/internal-order-form">Internal Order Form</a></li>
    <li *ngIf="!(isAuthenticated$ | async)" style="float: right"><app-login style="height: 100%"></app-login></li>
    <li *ngIf="isAuthenticated$ | async" style="float: right"><app-logout></app-logout></li>
    <li *ngIf="isEverywhereUser$ | async"><a href="{{environment.api.serverUrl}}swagger-ui/index.html" target="_blank">Documentation</a></li>
  </ul>
</nav>
