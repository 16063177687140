import {Component, inject} from '@angular/core';
import {CustomerService} from "../../service/customer.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {catchError, of} from "rxjs";

@Component({
  selector: 'app-create-customer',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './create-customer.component.html',
  styleUrl: './create-customer.component.css'
})
export class CreateCustomerComponent {
  customerService: CustomerService = inject(CustomerService);
  createCustomerForm: FormGroup = this.formBuilder.group({
    emailAddress: [null, [Validators.required, Validators.email]]
  });

  successMessage: string | null = null;
  errorMessage: string | null = null;
  submitButtonDisabled: boolean = false;

  constructor(private formBuilder: FormBuilder) {
  }

  submit() {
    this.submitButtonDisabled = true;
    this.errorMessage = null;
    this.successMessage = null;
    const emailAddress = this.emailAddress?.value;
    if (emailAddress) {
      this.customerService.createCustomer(emailAddress).pipe(
        catchError(err => {
          console.log(err);
          return of(false);
        })
      ).subscribe(response => {
        if (!response) {
          this.errorMessage = "Could not create user with email address: " + this.emailAddress?.value;
        } else if (typeof response === "string") {
          this.successMessage = response;
        }
        this.submitButtonDisabled = false;
      });
    }
  }

  get emailAddress() {
    return this.createCustomerForm.get('emailAddress');
  }
}
