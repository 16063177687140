<form *ngIf="!formSubmitted" [formGroup]="orderForm" class="order-form">
  <div class="mb-3">
    <label for="companyName" class="form-label">Company Name*</label>
    <input id="companyName" class="form-control" type="text" formControlName="companyName" [maxlength]="COMPANY_NAME_MAX_CHARS" required>
    <div *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)" class="alert alert-danger">
      <div *ngIf="companyName?.errors?.['required']">Company Name is required.</div>
      <div *ngIf="companyName?.errors?.['maxlength']">Company name cannot exceed {{COMPANY_NAME_MAX_CHARS}} characters.</div>
    </div>
  </div>

  <div class="mb-3">
      <label for="orderType" class="form-label">Order Type*</label>
      <mat-select id="orderType" class="form-control" formControlName="orderType">
        <mat-option value="" selected disabled hidden>Choose here</mat-option>
        <mat-option value="NEW">New</mat-option>
        <mat-option value="EXISTING">Existing</mat-option>
        <mat-option value="DEACTIVATION">Deactivation</mat-option>
<!--        <option value="REPLACEMENT">Replacement</option>-->
      </mat-select>
      <div *ngIf="orderType?.invalid && (orderType?.dirty || orderType?.touched)" class="alert alert-danger">
        <div *ngIf="orderType?.errors?.['required']">Order Type is required.</div>
      </div>
  </div>

  <button class="btn btn-primary" type="submit" (click)="submit()" [disabled]="orderForm.invalid || processingSubmit">SUBMIT</button>
  <span *ngIf="processingSubmit">Processing...</span>
  <span *ngIf="postSubmitError" style="padding-left: 5px; color: red; font-weight: bold">Could not process order.</span>
</form>
<div *ngIf="formSubmitted" class="order-confirmation">
  <h3 class="mb-3">Thank you! Your order has been received.</h3>
  <div class="mb-3" style="color:gray;">Order #{{orderNumber}}</div>
  <button class="btn btn-outline-primary" (click)="refreshForm()">start another order</button>
</div>
