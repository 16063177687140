import {Address} from "./address";

export class EmergencyContact {
  constructor(
    public contact_id: number | null,
    public address: Address,
    public firstName: string,
    public lastName: string,
    public phoneNumber: string,
    public email: string,
    public additionalInformation: string | null,
    public ec1Name: string,
    public ec1Country: string,
    public ec1PhoneNumber: string,
    public ec1Email: string,
    public ec2Name: string,
    public ec2Country: string,
    public ec2PhoneNumber: string,
    public ec2Email: string,
  ) {}
}

export const EC_FIRST_NAME_MAX_CHARS = 30;
export const EC_LAST_NAME_MAX_CHARS  = 50;
export const EC_PHONE_NUMBER_MAX_CHARS = 20;
export const EC_EMAIL_MAX_CHARS = 320;
export const ADDITIONAL_INFO_MAX_CHARS = 255;
export const EC1_NAME_MAX_CHARS = 80;
export const EC1_COUNTRY_MAX_CHARS  = 50;
export const EC1_PHONE_NUMBER_MAX_CHARS = 20;
export const EC1_EMAIL_MAX_CHARS = 320;
export const EC2_NAME_MAX_CHARS = 80;
export const EC2_COUNTRY_MAX_CHARS  = 50;
export const EC2_PHONE_NUMBER_MAX_CHARS = 20;
export const EC2_EMAIL_MAX_CHARS = 320;
