<div style="width: 70%; margin-right: auto; margin-left: auto">
  <form [formGroup]="createCustomerForm">
    <label for="emailAddress" class="form-label" style="margin-bottom: 15px;">Enter the email address the customer will use to access the order form.</label>
    <div class="row g-3" style="margin-bottom: 15px;">
      <div class="col-9">
        <input id="emailAddress" class="form-control col-8" type="email" formControlName="emailAddress" required>
      </div>
      <span class="col-3">
        <button class="btn btn-primary" type="submit" (click)="submit()" [disabled]="createCustomerForm.invalid || submitButtonDisabled">CREATE</button>
      </span>
      <div *ngIf="emailAddress?.invalid && (emailAddress?.dirty || emailAddress?.touched)" class="alert alert-danger col-9">
        <div *ngIf="emailAddress?.errors?.['required']">Email Address is required.</div>
        <div *ngIf="emailAddress?.errors?.['email']">Please provide a valid email address.</div>
      </div>
    </div>
    <div *ngIf="successMessage" class="alert alert-success col-9">
      {{successMessage}}
    </div>
    <div *ngIf="errorMessage" class="alert alert-danger col-9">
      {{errorMessage}}
    </div>
  </form>
</div>
