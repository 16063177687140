import {Component, inject, Input} from '@angular/core';
import {Order} from "../../model/order";
import {OrderService} from "../../service/order.service";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-delete-order',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ],
  template: `
    <mat-dialog-content>
      <p>
        Are you sure you want to delete Order #{{order.orderId}} for {{order.companyName}}?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-button (click)="delete()">Yes</button>
    </mat-dialog-actions>
  `,
  styleUrl: './delete-order.component.css'
})
export class DeleteOrderComponent {
  @Input() order!: Order;
  orderService = inject(OrderService);

  constructor(public dialogRef: MatDialogRef<DeleteOrderComponent>) {
  }

  public setOrder(order: Order) {
    this.order = order;
  }

  delete() {
    this.orderService.deleteOrder(this.order.orderId).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
