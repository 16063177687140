export const environment = {
  production: undefined,
  auth0: {
    domain: 'login-back-office-dev.evywh.com',
    clientId: '9dSu73G1HLMz8t7Ivdwv6wSBHDb2EoPs',
    authorizationParams: {
      redirect_uri: 'https://back-office-dev.evywh.com',
      audience: 'https://back-office-everywhere/',
    },
    errorPath: '/callback',
  },
  api: {
    serverUrl: 'https://back-office-dev.evywh.com/',
  },
  apiUrlPrefix: 'https://back-office-dev.evywh.com/v1/api/backoffice/',
  customerRoleName: 'Customer',
  everywhereUserRoleName: 'Everywhere User',
};
