import {Component, inject} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {AsyncPipe, NgIf} from "@angular/common";
import {LoginComponent} from "../../auth/login/login.component";
import {LogoutComponent} from "../../auth/logout/logout.component";
import {map} from "rxjs";
import {environment} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    AsyncPipe,
    NgIf,
    LoginComponent,
    LogoutComponent
  ],
  providers: [
    CookieService
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  cookieService = inject(CookieService);
  constructor(private auth: AuthService) {
    this.auth.getAccessTokenSilently().subscribe(accessToken => {
      this.cookieService.set('jwt', accessToken, {expires: 3600, path: '/'});
    });
  }

  isAuthenticated$ = this.auth.isAuthenticated$;

  isEverywhereUser$ = this.auth.idTokenClaims$.pipe(map(idTokenClaims => {
    if (idTokenClaims) {
      const roles: string[] = (idTokenClaims as any)['/roles'];
      return roles.includes(environment.everywhereUserRoleName);
    }

    return false;
  }));
  protected readonly environment = environment;
}
