export class Address {
  constructor(
    public addressId: number | null,
    public address: string,
    public address2: string | null,
    public city: string,
    public state: string,
    public zipCode: string,
    public country: string,
    public citizenship: string | null,
  ) {}
}

export const ADDRESS_MAX_CHARS = 100;
export const ADDRESS2_MAX_CHARS = 100;
export const CITY_MAX_CHARS = 60;
export const STATE_MAX_CHARS = 50;
export const ZIP_CODE_MAX_CHARS = 16;
export const COUNTRY_MAX_CHARS = 55;
export const CITIZENSHIP_MAX_CHARS = 55;
