import {Component, inject, Inject} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {DOCUMENT} from "@angular/common";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  providers: [CookieService],
  template: `
    <button class="button__logout" (click)="handleLogout()">Log Out</button>
  `,
  styleUrl: './logout.component.css'
})
export class LogoutComponent {
  cookieService = inject(CookieService);
  constructor(
    private auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  handleLogout(): void {
    this.cookieService.delete("jwt");
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
  }
}
