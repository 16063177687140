import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [],
  template: `
    <h2>Welcome to the Back Office Project!</h2>
    <p>This app will allow EVERYWHERE employees to perform IMEI validations and device activations via Garmin, Iridium, and EVERYWHERE Hub.</p>
  `,
  styleUrl: './home.component.css'
})
export class HomeComponent {

}
