import {Component, inject, Input, OnChanges} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {
  COMPANY_NAME_MAX_CHARS,
  FIRST_NAME_MAX_CHARS,
  LAST_NAME_MAX_CHARS,
  Order, PHONE_NUMBER_MAX_CHARS, TEAM_NAME_MAX_CHARS
} from "../../model/order";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {OrderService} from "../../service/order.service";
import {convert} from "../../utils/OrderFormConverter";
import {HardwareColors, HardwareModels} from "../../utils/HardwareModelConstants";
import {PlanTypes} from "../../utils/PlanTypeConstants";
import {OrderTypes} from "../../utils/OrderTypeConstants";
import {
  getValidators,
  processEmergencyContactChange,
  processFormSelectionChange
} from "../../utils/OrderFormValidators";
import {
  ADDRESS2_MAX_CHARS,
  ADDRESS_MAX_CHARS, CITIZENSHIP_MAX_CHARS,
  CITY_MAX_CHARS, COUNTRY_MAX_CHARS,
  STATE_MAX_CHARS, ZIP_CODE_MAX_CHARS
} from "../../model/address";
import {
  ADDITIONAL_INFO_MAX_CHARS,
  EC1_COUNTRY_MAX_CHARS,
  EC1_EMAIL_MAX_CHARS,
  EC1_NAME_MAX_CHARS,
  EC1_PHONE_NUMBER_MAX_CHARS,
  EC2_COUNTRY_MAX_CHARS,
  EC2_EMAIL_MAX_CHARS,
  EC2_NAME_MAX_CHARS,
  EC2_PHONE_NUMBER_MAX_CHARS,
  EC_EMAIL_MAX_CHARS,
  EC_FIRST_NAME_MAX_CHARS,
  EC_LAST_NAME_MAX_CHARS,
  EC_PHONE_NUMBER_MAX_CHARS
} from "../../model/emergencyContact";

@Component({
  selector: 'app-edit-order-form',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgForOf,
    NgClass
  ],
  templateUrl: './edit-order-form.component.html',
  styleUrl: './edit-order-form.component.css'
})
export class EditOrderFormComponent implements OnChanges {
  orderService = inject(OrderService);

  @Input() order!: Order;
  editOrderForm: FormGroup | null = null;
  isEditing = false;
  failedEdit = false;

  planTypes = [PlanTypes.GLOBAL_SOS, PlanTypes.BASIC, PlanTypes.LOW, PlanTypes.STANDARD, PlanTypes.UNLIMITED];
  hardwareTypes = [HardwareModels.GARMIN67I, HardwareModels.INREACHMESSENGER, HardwareModels.INREACHMINI2, HardwareModels.MONTANA700I, HardwareModels.MONTANA750I];
  hardwareColors = [HardwareColors.UNKNOWN, HardwareColors.ORANGE, HardwareColors.BLACK];

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnChanges() {
    if (this.order) {
      this.editOrderForm = this.createForm();
      this.editOrderForm.disable();
      if (this.order.orderType === OrderTypes.NEW && !this.planTypes.includes(PlanTypes.NO_SERVICE_PLAN)) {
        this.planTypes.push(PlanTypes.NO_SERVICE_PLAN);
      }

      processFormSelectionChange(this.order.orderType!, this.editOrderForm, true);
      this.updateEmergencyContact?.valueChanges.subscribe(change => {
        processEmergencyContactChange(change, this.editOrderForm!);
      });
    }
  }

  orderTypeDisplayName(orderType: string | null) {
    return orderType === OrderTypes.NEW ? 'New device(s)' : 'Existing device(s)';
  }

  enableEditing() {
    this.editOrderForm!.enable();
    this.isEditing = true;
  }

  cancelEditing() {
    this.ngOnChanges();
    this.isEditing = false;
  }

  confirmUpdate() {
    const updatedOrder = convert(this, this.order.orderType!, this.order.creatorEmail!);
    if (updatedOrder.shippingAddress && this.order.shippingAddress) {
      updatedOrder.shippingAddress.addressId = this.order.shippingAddress.addressId;
    }

    if (updatedOrder.emergencyContact && this.order.emergencyContact) {
      updatedOrder.emergencyContact.contact_id = this.order.emergencyContact.contact_id;
    }

    this.orderService.updateOrder(this.order.orderId, updatedOrder).subscribe({
      next: (order) => {
        if (order.body) {
          this.order = order.body;
          this.ngOnChanges();
        }
        this.isEditing = false;
        this.failedEdit = false;
      },
      error: (err) => {
        console.log(err);
        this.failedEdit = true;
      }
    });
    this.isEditing = false;
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [{value: this.order.firstName, disabled: true}, getValidators(FIRST_NAME_MAX_CHARS)],
      lastName: [this.order.lastName, getValidators(LAST_NAME_MAX_CHARS)],
      companyName: [this.order.companyName, getValidators(COMPANY_NAME_MAX_CHARS)],
      hardwareModel: [this.order.hardwareModel],
      hardwareColor: [this.order.hardwareColor],
      hardwareQuantity: [this.order.hardwareQuantity, Validators.min(1)],
      servicePlan: [this.order.planType],
      phoneNumber: [this.order.phoneNumber],
      streetAddress: [this.order.shippingAddress?.address],
      streetAddress2: [this.order.shippingAddress?.address2],
      city: [this.order.shippingAddress?.city],
      state: [this.order.shippingAddress?.state],
      postalCode: [this.order.shippingAddress?.zipCode],
      country: [this.order.shippingAddress?.country],
      teamName: [this.order.teamName],
      updateEmergencyContact: [this.order.updateEmergencyContacts, Validators.required],
      ecFirstName: [this.order.emergencyContact?.firstName],
      ecLastName: [this.order.emergencyContact?.lastName],
      ecStreetAddress: [this.order.emergencyContact?.address.address],
      ecCity: [this.order.emergencyContact?.address.city],
      ecState: [this.order.emergencyContact?.address.state],
      ecPostalCode: [this.order.emergencyContact?.address.zipCode],
      ecCountry: [this.order.emergencyContact?.address.country],
      ecCitizenship: [this.order.emergencyContact?.address.citizenship],
      ecPhone: [this.order.emergencyContact?.phoneNumber],
      ecEmail: [this.order.emergencyContact?.email],
      ecAdditionalInfo: [this.order.emergencyContact?.additionalInformation],
      ec1Name: [this.order.emergencyContact?.ec1Name],
      ec1Country: [this.order.emergencyContact?.ec1Country],
      ec1Phone: [this.order.emergencyContact?.ec1PhoneNumber],
      ec1Email: [this.order.emergencyContact?.ec1Email],
      ec2Name: [this.order.emergencyContact?.ec2Name],
      ec2Country: [this.order.emergencyContact?.ec2Country],
      ec2Phone: [this.order.emergencyContact?.ec2PhoneNumber],
      ec2Email: [this.order.emergencyContact?.ec2Email],
    });
  }

  get firstName() {
    return this.editOrderForm?.get('firstName');
  }

  get lastName() {
    return this.editOrderForm?.get('lastName');
  }

  get companyName() {
    return this.editOrderForm?.get('companyName');
  }

  get hardwareModel() {
    return this.editOrderForm?.get('hardwareModel');
  }

  get hardwareColor() {
    return this.editOrderForm?.get('hardwareColor');
  }

  get hardwareQuantity() {
    return this.editOrderForm?.get('hardwareQuantity');
  }

  get servicePlan() {
    return this.editOrderForm?.get('servicePlan');
  }

  get phoneNumber() {
    return this.editOrderForm?.get('phoneNumber');
  }

  get streetAddress() {
    return this.editOrderForm?.get('streetAddress');
  }

  get streetAddress2() {
    return this.editOrderForm?.get('streetAddress2');
  }

  get city() {
    return this.editOrderForm?.get('city');
  }

  get state() {
    return this.editOrderForm?.get('state');
  }

  get postalCode() {
    return this.editOrderForm?.get('postalCode');
  }

  get country() {
    return this.editOrderForm?.get('country');
  }

  get teamName() {
    return this.editOrderForm?.get('teamName');
  }

  get updateEmergencyContact() {
    return this.editOrderForm?.get('updateEmergencyContact');
  }

  get ecFirstName() {
    return this.editOrderForm?.get('ecFirstName');
  }

  get ecLastName() {
    return this.editOrderForm?.get('ecLastName');
  }

  get ecStreetAddress() {
    return this.editOrderForm?.get('ecStreetAddress');
  }

  get ecCity() {
    return this.editOrderForm?.get('ecCity');
  }

  get ecState() {
    return this.editOrderForm?.get('ecState');
  }

  get ecPostalCode() {
    return this.editOrderForm?.get('ecPostalCode');
  }

  get ecCountry() {
    return this.editOrderForm?.get('ecCountry');
  }

  get ecCitizenship() {
    return this.editOrderForm?.get('ecCitizenship');
  }

  get ecPhone() {
    return this.editOrderForm?.get('ecPhone');
  }

  get ecEmail() {
    return this.editOrderForm?.get('ecEmail');
  }

  get ecAdditionalInfo() {
    return this.editOrderForm?.get('ecAdditionalInfo');
  }

  get ec1Name() {
    return this.editOrderForm?.get('ec1Name');
  }

  get ec1Country() {
    return this.editOrderForm?.get('ec1Country');
  }

  get ec1Phone() {
    return this.editOrderForm?.get('ec1Phone');
  }

  get ec1Email() {
    return this.editOrderForm?.get('ec1Email');
  }

  get ec2Name() {
    return this.editOrderForm?.get('ec2Name');
  }

  get ec2Country() {
    return this.editOrderForm?.get('ec2Country');
  }

  get ec2Phone() {
    return this.editOrderForm?.get('ec2Phone');
  }

  get ec2Email() {
    return this.editOrderForm?.get('ec2Email');
  }

  protected readonly INREACHMINI2 = HardwareModels.INREACHMINI2;
  protected readonly NEW = OrderTypes.NEW;
  protected readonly FIRST_NAME_MAX_CHARS = FIRST_NAME_MAX_CHARS;
  protected readonly LAST_NAME_MAX_CHARS = LAST_NAME_MAX_CHARS;
  protected readonly PHONE_NUMBER_MAX_CHARS = PHONE_NUMBER_MAX_CHARS;
  protected readonly COMPANY_NAME_MAX_CHARS = COMPANY_NAME_MAX_CHARS;
  protected readonly TEAM_NAME_MAX_CHARS = TEAM_NAME_MAX_CHARS;
  protected readonly ADDRESS_MAX_CHARS = ADDRESS_MAX_CHARS;
  protected readonly ADDRESS2_MAX_CHARS = ADDRESS2_MAX_CHARS;
  protected readonly CITY_MAX_CHARS = CITY_MAX_CHARS;
  protected readonly STATE_MAX_CHARS = STATE_MAX_CHARS;
  protected readonly ZIP_CODE_MAX_CHARS = ZIP_CODE_MAX_CHARS;
  protected readonly COUNTRY_MAX_CHARS = COUNTRY_MAX_CHARS;
  protected readonly EC_FIRST_NAME_MAX_CHARS = EC_FIRST_NAME_MAX_CHARS;
  protected readonly EC_LAST_NAME_MAX_CHARS = EC_LAST_NAME_MAX_CHARS;
  protected readonly EC_EMAIL_MAX_CHARS = EC_EMAIL_MAX_CHARS;
  protected readonly EC_PHONE_NUMBER_MAX_CHARS = EC_PHONE_NUMBER_MAX_CHARS;
  protected readonly CITIZENSHIP_MAX_CHARS = CITIZENSHIP_MAX_CHARS;
  protected readonly ADDITIONAL_INFO_MAX_CHARS = ADDITIONAL_INFO_MAX_CHARS;
  protected readonly EC1_NAME_MAX_CHARS = EC1_NAME_MAX_CHARS;
  protected readonly EC1_COUNTRY_MAX_CHARS = EC1_COUNTRY_MAX_CHARS;
  protected readonly EC1_PHONE_NUMBER_MAX_CHARS = EC1_PHONE_NUMBER_MAX_CHARS;
  protected readonly EC1_EMAIL_MAX_CHARS = EC1_EMAIL_MAX_CHARS;
  protected readonly EC2_NAME_MAX_CHARS = EC2_NAME_MAX_CHARS;
  protected readonly EC2_COUNTRY_MAX_CHARS = EC2_COUNTRY_MAX_CHARS;
  protected readonly EC2_PHONE_NUMBER_MAX_CHARS = EC2_PHONE_NUMBER_MAX_CHARS;
  protected readonly EC2_EMAIL_MAX_CHARS = EC2_EMAIL_MAX_CHARS;
}
