import {CanActivateFn, Router} from '@angular/router';
import { inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { map } from "rxjs";
import {environment} from "../../environments/environment";

export const everywhereGuard: CanActivateFn = (route, state) => {
  let router = inject(Router);

  return inject(AuthService).idTokenClaims$.pipe(map((idTokenClaims => {
    const roles: string[] = (idTokenClaims as any)['/roles'];
    if (roles.includes(environment.everywhereUserRoleName)) {
      return true;
    }

    // non-everywhere user should be routed to order form
    router.navigate(['/order-form']).then(r => {
      if (!r) {
        console.log('Error redirecting customer to order page.');
      }
    });

    return false;
  })));
};
