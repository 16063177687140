export enum HardwareModels {
  INREACHMESSENGER = "INREACHMESSENGER",
  INREACHMINI2 = "INREACHMINI2",
  INREACHMINI2_ORANGE = "INREACHMINI2_ORANGE",
  INREACHMINI2_BLACK = "INREACHMINI2_BLACK",
  GARMIN67I = "GARMIN67I",
  MONTANA700I = "MONTANA700I",
  MONTANA750I = "MONTANA750I"
}

export enum HardwareColors {
  UNKNOWN = "UNKNOWN",
  ORANGE = "ORANGE",
  BLACK = "BLACK"
}
