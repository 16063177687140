import {ImeiInfo} from "../model/imeiInfo";

export function formatInvalidImeiStatuses(imeis: ImeiInfo[]): InvalidImeiMessages {
  const invalidImeiMessages: InvalidImeiMessages = new InvalidImeiMessages();
  const activeOnHubMessages: string[] = [];
  const deactivateImeiMessages: string[] = [];
  const malformedImeiMessages: string[] = [];
  const notFoundImeiMessages: string[] = [];

  imeis.forEach(imeiInfo => {
    switch (imeiInfo.imeiStatus) {
      case 'ACTIVE':
        activeOnHubMessages.push(imeiInfo.imei + ' (' + imeiInfo.hubDeviceName + ')');
        break;
      case 'DEACTIVATE':
        deactivateImeiMessages.push(imeiInfo.imei + ' (assigned to Garmin user with ID: ' + imeiInfo.garminUserId + ')');
        break;
      case 'MALFORMED':
        malformedImeiMessages.push(imeiInfo.imei);
        break;
      case 'GARMIN_NOT_FOUND':
        notFoundImeiMessages.push(imeiInfo.imei);
        break;
      default:
        break;
    }
  });

  if (activeOnHubMessages.length > 0) {
    invalidImeiMessages.activeOnHubMessage = "The following IMEIs are already active on the EVERYWHERE hub: " + activeOnHubMessages.join(', ');
  }

  if (deactivateImeiMessages.length > 0) {
    invalidImeiMessages.deactivateImeiMessage = "The following IMEIs are already active on Garmin: " + deactivateImeiMessages.join(', ');
  }

  if (malformedImeiMessages.length > 0) {
    invalidImeiMessages.malformedImeiMessage = "The following IMEIs are formatted incorrectly: " + malformedImeiMessages.join(', ');
  }

  if (notFoundImeiMessages.length > 0) {
    invalidImeiMessages.notFoundImeiMessage = "The following IMEIs were not found on Garmin: " + notFoundImeiMessages.join(', ');
  }

  return invalidImeiMessages;
}

export class InvalidImeiMessages {
  public malformedImeiMessage: string | null = null;
  public notFoundImeiMessage: string | null = null;
  public deactivateImeiMessage: string | null = null;
  public activeOnHubMessage: string | null = null;
}
