export class ImeiInfo {
  public imeiInfoId: number | null = null;
  public imei: string;
  public imeiStatus: string | null = null;
  public activationStatus: string | null = null;
  public deactivationStatus: string | null = null;
  public teamId: string | null = null;
  public garminNetworkStatus: number | null = null;
  public garminUserId: number | null = null;
  public hubDeviceName: string | null = null;
  public alias: string | null = null;
  public dedicatedSmsCountryCode: string | null = null;
  public emailAddress: string | null = null;
  public garminTenant: string | null = null;
  public activationType: string | null = null;
  public secureInReach: boolean = false;
  public validationErrors: string | null = "";

  public constructor(imei: string) {
      this.imei = imei;
  }
}
