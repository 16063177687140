<div *ngIf="!formSelection && !formSubmitted" style="width: 70%; margin-right: auto; margin-left: auto">
  <h3 class="mb-3" style="text-align: center">Hello! What would you like to do?</h3>
  <div class="mb-3">
    <button class="btn btn-outline-primary btn-lg btn-block w-100" type="button" (click)="selectForm(NEW)">
      I want to order new devices.
    </button>
  </div>
  <div class="mb-3">
    <button class="btn btn-outline-primary btn-lg w-100" type="button" (click)="selectForm(EXISTING)">
      I have my own devices that I want to add to EVERYWHERE.
    </button>
  </div>
</div>
<div *ngIf="formSelection && !formSubmitted" style="width: 70%; margin-right: auto; margin-left: auto">
  <div class="mb-3" style="text-align: center;">
    <span *ngIf="formSelection === NEW" class="mb-3" style="font-size: 24px;">Order New Devices</span>
    <span *ngIf="formSelection === EXISTING" class="mb-3" style="font-size: 24px;">Activate Existing Devices</span>
    <span class="clickable" style="font-weight: lighter; color: gray; font-size: 12px; margin-left: 10px" (click)="refreshForm()">choose different form</span>
  </div>
  <form [formGroup]="orderForm">
    <div *ngIf="formSelection === EXISTING">
      <h1 style="font-size: 20px; padding-bottom: 15px;">
        <span style="font-family: Arial, Helvetica, sans-serif; color: #ff9902; font-weight: normal">CUSTOMER INFORMATION</span>
      </h1>
      <div id="customerInfo" style="margin-bottom: 20px;" class="bg-light border border-secondary border-1 rounded-2 row g-3">
        <div class="mb-3 col-6">
          <label for="firstName" class="form-label">First Name*</label>
          <input id="firstName" class="form-control" type="text" formControlName="firstName" [maxlength]="FIRST_NAME_MAX_CHARS" required>
          <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)" class="alert alert-danger">
            <div *ngIf="firstName?.errors?.['required']">First Name is required.</div>
            <div *ngIf="firstName?.errors?.['maxlength']">First Name cannot exceed {{FIRST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-6">
          <label for="lastName" class="form-label">Last Name*</label>
          <input id="lastName" class="form-control" type="text" formControlName="lastName" [maxlength]="LAST_NAME_MAX_CHARS" required>
          <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)" class="alert alert-danger">
            <div *ngIf="lastName?.errors?.['required']">Last Name is required.</div>
            <div *ngIf="lastName?.errors?.['maxlength']">Last Name cannot exceed {{LAST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-12">
          <label for="companyName" class="form-label">Company Name*</label>
          <input id="companyName" class="form-control" type="text" formControlName="companyName" [maxlength]="COMPANY_NAME_MAX_CHARS" required>
          <div *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)" class="alert alert-danger">
            <div *ngIf="companyName?.errors?.['required']">Company Name is required.</div>
            <div *ngIf="companyName?.errors?.['maxlength']">Company name cannot exceed {{COMPANY_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-12">
          <input type="checkbox" formControlName="updateMainPoc">
          <span> Please update my account so that I am the main point of contact for all billing communications going foward.</span>
        </div>
      </div>
    </div>

    <div>
      <h1 style="font-size: 20px; padding-bottom: 15px;">
        <span style="font-family: Arial, Helvetica, sans-serif; color: #ff9902; font-weight: normal">DEVICE INFORMATION</span>
      </h1>
      <div id="deviceInfo" style="margin-bottom: 20px;" class="bg-light border border-secondary border-1 rounded-2 row g-3">
        <div *ngIf="formSelection  === NEW" class="col-12 row g-3">
          <div class="mb-3 col-8">
            <label for="hardwareModel" class="form-label">Hardware Devices*</label>
            <select id="hardwareModel" class="form-control" formControlName="hardwareModel">
              <option value="" selected disabled hidden>Choose here</option>
              <option value="GARMIN67I">GPSMAP 67i</option>
              <option value="INREACHMESSENGER">inReach Messenger</option>
              <option value="INREACHMINI2_ORANGE">inReach Mini2 (orange)</option>
              <option value="INREACHMINI2_BLACK">inReach Mini2 (black)</option>
              <option value="MONTANA700I">Montana 700i</option>
              <option value="MONTANA750I">Montana 750i</option>
            </select>
            <div *ngIf="hardwareModel?.invalid && (hardwareModel?.dirty || hardwareModel?.touched)" class="alert alert-danger">
              <div *ngIf="hardwareModel?.errors?.['required']">Hardware Model is required.</div>
            </div>
          </div>

          <div class="mb-3 col-4">
            <label for="hardwareQuantity" class="form-label">Quantity*</label>
            <input type="number" id="hardwareQuantity" class="form-control" formControlName="hardwareQuantity" min="1">
            <div *ngIf="hardwareQuantity?.invalid && (hardwareQuantity?.dirty || hardwareQuantity?.touched)" class="alert alert-danger">
              <div *ngIf="hardwareQuantity?.errors?.['required']">Hardware Quantity is required.</div>
            </div>
          </div>
        </div>

        <div class="mb-3 col-12" *ngIf="formSelection === NEW">
          <label for="servicePlanNew" class="form-label">Service Plan*</label>
          <select id="servicePlanNew" class="form-control" formControlName="servicePlan">
            <option value="" selected disabled hidden>Choose here</option>
            <option value="GLOBAL_SOS">Global SOS</option>
            <option value="BASIC">Basic</option>
            <option value="LOW">Low</option>
            <option value="STANDARD">Standard</option>
            <option value="UNLIMITED">Unlimited</option>
            <option value="NO_SERVICE_PLAN">No Service Plan</option>
          </select>
          <div *ngIf="servicePlan?.invalid && (servicePlan?.dirty || servicePlan?.touched)" class="alert alert-danger">
            <div *ngIf="servicePlan?.errors?.['required']">Service Plan is required.</div>
          </div>
        </div>

        <div class="mb-3 col-12" *ngIf="formSelection === EXISTING">
          <label for="servicePlan" class="form-label">Service Plan*</label>
          <select id="servicePlan" class="form-control" formControlName="servicePlan">
            <option value="" selected disabled hidden>Choose here</option>
            <option value="GLOBAL_SOS">Global SOS</option>
            <option value="BASIC">Basic</option>
            <option value="LOW">Low</option>
            <option value="STANDARD">Standard</option>
            <option value="UNLIMITED">Unlimited</option>
          </select>
          <div *ngIf="servicePlan?.invalid && (servicePlan?.dirty || servicePlan?.touched)" class="alert alert-danger">
            <div *ngIf="servicePlan?.errors?.['required']">Service Plan is required.</div>
          </div>
        </div>

        <div class="mb-3 col-12">
          <label for="teamName" class="form-label">Team Name</label>
          <span style="color: gray; font-size: 12px; padding-left: 5px;">team in EVERYWHERE hub where you would like devices to be added (optional)</span>
          <input id="teamName" type="text" class="form-control" formControlName="teamName" [maxlength]="TEAM_NAME_MAX_CHARS">
          <div *ngIf="teamName?.invalid && (teamName?.dirty || teamName?.touched)" class="alert alert-danger">
            <div *ngIf="teamName?.errors?.['required']">Team Name cannot exceed {{TEAM_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div *ngIf="formSelection === EXISTING">
          <div class="mb-3">
            <label for="imeis" class="form-label">IMEI(s)*</label>
            <span style="color: gray; font-size: 12px; padding-left: 5px;">comma or line-separated list</span>
            <textarea [cdkTextareaAutosize]="true" id="imeis" class="form-control" formControlName="imeis"></textarea>
          </div>
          <div class="mb-3">
            <button class="btn btn-primary" type="button"
                    (click)="validateImeis()"
                    [disabled]="validationDisabled">validate imeis</button>
          </div>
          <div class="alert alert-success" role="alert" *ngIf="imeiValidationResult && imeis">IMEIs are valid and ready to be activated.</div>
          <div class="alert alert-danger" role="alert" *ngIf="hasErrorMessage()">
            <div>{{malformedImeiMessage}}</div>
            <div>{{notFoundImeiMessage}}</div>
            <div>{{deactivateImeiMessage}}</div>
            <div>{{activeOnHubMessage}}</div>
          </div>
          <div *ngIf="imeis?.invalid && (imeis?.dirty || imeis?.touched)" class="alert alert-danger">
            <div *ngIf="imeis?.errors?.['required']">IMEIs are required.</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="formSelection === NEW">
      <h1 style="font-size: 20px; padding-bottom: 15px;">
        <span style="font-family: Arial, Helvetica, sans-serif; color: #ff9902; font-weight: normal">SHIPPING INFORMATION</span>
      </h1>
      <div id="shippingInfo" style="margin-bottom: 20px;" class="bg-light border border-secondary border-1 rounded-2 row g-3">
        <div class="mb-3 col-6">
          <label for="shippingFirstName" class="form-label">First Name*</label>
          <input id="shippingFirstName" class="form-control" type="text" formControlName="firstName" [maxlength]="FIRST_NAME_MAX_CHARS" required>
          <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)" class="alert alert-danger">
            <div *ngIf="firstName?.errors?.['required']">First Name is required.</div>
            <div *ngIf="firstName?.errors?.['maxlength']">First Name cannot exceed {{FIRST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-6">
          <label for="shippingLastName" class="form-label">Last Name*</label>
          <input id="shippingLastName" class="form-control" type="text" formControlName="lastName" [maxlength]="LAST_NAME_MAX_CHARS" required>
          <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)" class="alert alert-danger">
            <div *ngIf="lastName?.errors?.['required']">Last Name is required.</div>
            <div *ngIf="firstName?.errors?.['maxlength']">Last Name cannot exceed {{LAST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-12">
          <label for="shippingCompanyName" class="form-label">Company Name*</label>
          <input id="shippingCompanyName" class="form-control" type="text" formControlName="companyName" [maxlength]="COMPANY_NAME_MAX_CHARS" required>
          <div *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)" class="alert alert-danger">
            <div *ngIf="companyName?.errors?.['required']">Company Name is required.</div>
            <div *ngIf="companyName?.errors?.['maxlength']">Company Name cannot exceed {{COMPANY_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-12">
          <label for="phoneNumber" class="form-label">Phone Number*</label>
          <input type="tel" id="phoneNumber" class="form-control" formControlName="phoneNumber" [maxlength]="PHONE_NUMBER_MAX_CHARS">
          <div *ngIf="phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched)" class="alert alert-danger">
            <div *ngIf="phoneNumber?.errors?.['required']">Phone Number is required.</div>
            <div *ngIf="phoneNumber?.errors?.['maxlength']">Phone Number cannot exceed {{PHONE_NUMBER_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-12">
          <label for="streetAddress" class="form-label">Street Address*</label>
          <input type="text" id="streetAddress" class="form-control" formControlName="streetAddress" [maxlength]="ADDRESS_MAX_CHARS">
          <div *ngIf="streetAddress?.invalid && (streetAddress?.dirty || streetAddress?.touched)" class="alert alert-danger">
            <div *ngIf="streetAddress?.errors?.['required']">Street Address is required.</div>
            <div *ngIf="streetAddress?.errors?.['maxlength']">Address cannot exceed {{ADDRESS_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-12">
          <label for="streetAddress2" class="form-label">Street Address 2</label>
          <input type="text" id="streetAddress2" class="form-control" formControlName="streetAddress2" [maxlength]="ADDRESS2_MAX_CHARS">
          <div *ngIf="streetAddress2?.invalid && (streetAddress2?.dirty || streetAddress2?.touched)" class="alert alert-danger">
            <div *ngIf="streetAddress2?.errors?.['maxlength']">Address2 cannot exceed {{ADDRESS2_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-6">
          <label for="city" class="form-label">City*</label>
          <input type="text" id="city" class="form-control" formControlName="city" [maxlength]="CITY_MAX_CHARS">
          <div *ngIf="city?.invalid && (city?.dirty || city?.touched)" class="alert alert-danger">
            <div *ngIf="city?.errors?.['required']">City is required.</div>
            <div *ngIf="city?.errors?.['maxlength']">City cannot exceed {{CITY_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-4">
          <label for="state" class="form-label">State/Province*</label>
          <input type="text" id="state" class="form-control" formControlName="state" [maxlength]="STATE_MAX_CHARS">
          <div *ngIf="state?.invalid && (state?.dirty || state?.touched)" class="alert alert-danger">
            <div *ngIf="state?.errors?.['required']">State/Province is required.</div>
            <div *ngIf="state?.errors?.['maxlength']">State cannot exceed {{STATE_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-2">
          <label for="postalCode" class="form-label">Zip/Postal Code*</label>
          <input type="text" id="postalCode" class="form-control" formControlName="postalCode" [maxlength]="ZIP_CODE_MAX_CHARS">
          <div *ngIf="postalCode?.invalid && (postalCode?.dirty || postalCode?.touched)" class="alert alert-danger">
            <div *ngIf="postalCode?.errors?.['required']">Zip/Postal Code is required.</div>
            <div *ngIf="postalCode?.errors?.['maxlength']">Zip Code cannot exceed {{ZIP_CODE_MAX_CHARS}} characters.</div>
          </div>
        </div>

        <div class="mb-3 col-md-12">
          <label for="country" class="form-label">Country/Region*</label>
          <input type="text" id="country" class="form-control" formControlName="country" [maxlength]="COUNTRY_MAX_CHARS">
          <div *ngIf="country?.invalid && (country?.dirty || country?.touched)" class="alert alert-danger">
            <div *ngIf="country?.errors?.['required']">Country is required.</div>
            <div *ngIf="country?.errors?.['maxlength']">Country cannot exceed {{COUNTRY_MAX_CHARS}} characters.</div>
          </div>
        </div>
      </div>
    </div>

    <h1 style="font-size: 20px; margin-bottom:25px;">
      <span style="font-family: Arial, Helvetica, sans-serif; color: #ff9902; font-weight: normal">EMERGENCY CONTACT INFORMATION</span>
    </h1>

    <div id="emergencyContactInfo" style="margin-bottom: 20px;" class="bg-light border border-secondary border-1 rounded-2 row g-3">
      <p style="font-size: .8rem;">We require emergency contacts to activate your account and to ensure your team members are notified in case of an emergency. This emergency contact information will be used for all devices. If you require emergency contact information for specific devices, please contact your EVERYWHERE Sales Team member at sales&#64;everywherecomms.com.</p>
      <div class="mb-3">
        <label for="updateEmergencyContact" class="form-label">Emergency Contact*</label>
        <select id="updateEmergencyContact" class="form-control" formControlName="updateEmergencyContact">
          <option value="" selected disabled hidden>Choose here</option>
          <option [ngValue]="false">Use my Existing Emergency Contacts</option>
          <option [ngValue]="true">New Emergency Contacts</option>
        </select>
        <div *ngIf="updateEmergencyContact?.invalid && (updateEmergencyContact?.dirty || updateEmergencyContact?.touched)" class="alert alert-danger">
          <div *ngIf="updateEmergencyContact?.errors?.['required']">Emergency Contact is required.</div>
        </div>
      </div>

      <div *ngIf="orderForm.controls['updateEmergencyContact'].value === true" class="row g-3">
        <div class="mb-3 col-6">
          <label for="ecFirstName" class="form-label">First Name*</label>
          <input type="text" id="ecFirstName" class="form-control" formControlName="ecFirstName" [maxlength]="EC_FIRST_NAME_MAX_CHARS">
          <div *ngIf="ecFirstName?.invalid && (ecFirstName?.dirty || ecFirstName?.touched)" class="alert alert-danger">
            <div *ngIf="ecFirstName?.errors?.['required']">First Name is required.</div>
            <div *ngIf="ecFirstName?.errors?.['maxlength']">First Name cannot exceed {{EC_FIRST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="ecLastName" class="form-label">Last Name*</label>
          <input type="text" id="ecLastName" class="form-control" formControlName="ecLastName" [maxlength]="EC_LAST_NAME_MAX_CHARS">
          <div *ngIf="ecLastName?.invalid && (ecLastName?.dirty || ecLastName?.touched)" class="alert alert-danger">
            <div *ngIf="ecLastName?.errors?.['required']">Last Name is required.</div>
            <div *ngIf="ecLastName?.errors?.['maxlength']">Last Name cannot exceed {{EC_LAST_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ecStreetAddress" class="form-label">Street Address*</label>
          <input type="text" id="ecStreetAddress" class="form-control" formControlName="ecStreetAddress" [maxlength]="ADDRESS_MAX_CHARS">
          <div *ngIf="ecStreetAddress?.invalid && (ecStreetAddress?.dirty || ecStreetAddress?.touched)" class="alert alert-danger">
            <div *ngIf="ecStreetAddress?.errors?.['required']">Street Address is required.</div>
            <div *ngIf="ecStreetAddress?.errors?.['maxlength']">Address cannot exceed {{ADDRESS_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="ecCity" class="form-label">City*</label>
          <input type="text" id="ecCity" class="form-control" formControlName="ecCity" [maxlength]="CITY_MAX_CHARS">
          <div *ngIf="ecCity?.invalid && (ecCity?.dirty || ecCity?.touched)" class="alert alert-danger">
            <div *ngIf="ecCity?.errors?.['required']">City is required.</div>
            <div *ngIf="ecCity?.errors?.['maxlength']">City cannot exceed {{CITY_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-4">
          <label for="ecState" class="form-label">State/Province*</label>
          <input type="text" id="ecState" class="form-control" formControlName="ecState" [maxlength]="STATE_MAX_CHARS">
          <div *ngIf="ecState?.invalid && (ecState?.dirty || ecState?.touched)" class="alert alert-danger">
            <div *ngIf="ecState?.errors?.['required']">State is required.</div>
            <div *ngIf="ecState?.errors?.['maxlength']">State cannot exceed {{STATE_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-2">
          <label for="ecPostalCode" class="form-label">Zip/Postal Code*</label>
          <input type="text" id="ecPostalCode" class="form-control" formControlName="ecPostalCode" [maxlength]="ZIP_CODE_MAX_CHARS">
          <div *ngIf="ecPostalCode?.invalid && (ecPostalCode?.dirty || ecPostalCode?.touched)" class="alert alert-danger">
            <div *ngIf="ecPostalCode?.errors?.['required']">Zip/Postal Code is required.</div>
            <div *ngIf="ecPostalCode?.errors?.['maxlength']">Zip/Postal Code cannot exceed {{ZIP_CODE_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="ecCountry" class="form-label">Country*</label>
          <input type="text" id="ecCountry" class="form-control" formControlName="ecCountry" [maxlength]="COUNTRY_MAX_CHARS">
          <div *ngIf="ecCountry?.invalid && (ecCountry?.dirty || ecCountry?.touched)" class="alert alert-danger">
            <div *ngIf="ecCountry?.errors?.['required']">Country is required.</div>
            <div *ngIf="ecCountry?.errors?.['maxlength']">Country cannot exceed {{COUNTRY_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-6">
          <label for="ecCitizenship" class="form-label">Citizenship*</label>
          <input type="text" id="ecCitizenship" class="form-control" formControlName="ecCitizenship" [maxlength]="CITIZENSHIP_MAX_CHARS">
          <div *ngIf="ecCitizenship?.invalid && (ecCitizenship?.dirty || ecCitizenship?.touched)" class="alert alert-danger">
            <div *ngIf="ecCitizenship?.errors?.['required']">Citizenship is required.</div>
            <div *ngIf="ecCitizenship?.errors?.['maxlength']">Citizenship cannot exceed {{CITIZENSHIP_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-4">
          <label for="ecPhone" class="form-label">Phone*</label>
          <input type="tel" id="ecPhone" class="form-control" formControlName="ecPhone" [maxlength]="EC_PHONE_NUMBER_MAX_CHARS">
          <div *ngIf="ecPhone?.invalid && (ecPhone?.dirty || ecPhone?.touched)" class="alert alert-danger">
            <div *ngIf="ecPhone?.errors?.['required']">Phone is required.</div>
            <div *ngIf="ecPhone?.errors?.['maxlength']">Phone cannot exceed {{EC_PHONE_NUMBER_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-8">
          <label for="ecEmail" class="form-label">Email*</label>
          <input type="email" id="ecEmail" class="form-control" formControlName="ecEmail" [maxlength]="EC_EMAIL_MAX_CHARS">
          <div *ngIf="ecEmail?.invalid && (ecEmail?.dirty || ecEmail?.touched)" class="alert alert-danger">
            <div *ngIf="ecEmail?.errors?.['required']">Emergency Contact #1: Email is required.</div>
            <div *ngIf="ecEmail?.errors?.['maxlength']">Email cannot exceed {{EC_EMAIL_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ecAdditionalInfo" class="form-label">Additional Information</label>
          <input type="text" id="ecAdditionalInfo" class="form-control" formControlName="ecAdditionalInfo" [maxlength]="ADDITIONAL_INFO_MAX_CHARS">
          <div *ngIf="ecAdditionalInfo?.invalid && (ecAdditionalInfo?.dirty || ecAdditionalInfo?.touched)" class="alert alert-danger">
            <div *ngIf="ecAdditionalInfo?.errors?.['maxlength']">Additional Information cannot exceed {{ADDITIONAL_INFO_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ec1Name" class="form-label">First Emergency Contact Name*</label>
          <input type="text" id="ec1Name" class="form-control" formControlName="ec1Name" [maxlength]="EC1_NAME_MAX_CHARS">
          <div *ngIf="ec1Name?.invalid && (ec1Name?.dirty || ec1Name?.touched)" class="alert alert-danger">
            <div *ngIf="ec1Name?.errors?.['required']">Name is required.</div>
            <div *ngIf="ec1Name?.errors?.['maxlength']">Name cannot exceed {{EC1_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ec1Country" class="form-label">First Emergency Contact Country*</label>
          <input type="text" id="ec1Country" class="form-control" formControlName="ec1Country" [maxlength]="EC1_COUNTRY_MAX_CHARS">
          <div *ngIf="ec1Country?.invalid && (ec1Country?.dirty || ec1Country?.touched)" class="alert alert-danger">
            <div *ngIf="ec1Country?.errors?.['required']">Country is required.</div>
            <div *ngIf="ec1Country?.errors?.['maxlength']">Country cannot exceed {{EC1_COUNTRY_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-4">
          <label for="ec1Phone" class="form-label">First Emergency Contact Phone*</label>
          <input type="tel" id="ec1Phone" class="form-control" formControlName="ec1Phone" [maxlength]="EC1_PHONE_NUMBER_MAX_CHARS">
          <div *ngIf="ec1Phone?.invalid && (ec1Phone?.dirty || ec1Phone?.touched)" class="alert alert-danger">
            <div *ngIf="ec1Phone?.errors?.['required']">Phone is required.</div>
            <div *ngIf="ec1Phone?.errors?.['maxlength']">Phone cannot exceed {{EC1_PHONE_NUMBER_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-8">
          <label for="ec1Email" class="form-label">First Emergency Contact Email*</label>
          <input type="email" id="ec1Email" class="form-control" formControlName="ec1Email" [maxlength]="EC1_EMAIL_MAX_CHARS">
          <div *ngIf="ec1Email?.invalid && (ec1Email?.dirty || ec1Email?.touched)" class="alert alert-danger">
            <div *ngIf="ec1Email?.errors?.['required']">Email is required.</div>
            <div *ngIf="ec1Email?.errors?.['maxlength']">Email cannot exceed {{EC1_EMAIL_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ec2Name" class="form-label">Second Emergency Contact Name*</label>
          <input type="text" id="ec2Name" class="form-control" formControlName="ec2Name" [maxlength]="EC2_NAME_MAX_CHARS">
          <div *ngIf="ec2Name?.invalid && (ec2Name?.dirty || ec2Name?.touched)" class="alert alert-danger">
            <div *ngIf="ec2Name?.errors?.['required']">Name is required.</div>
            <div *ngIf="ec2Name?.errors?.['maxlength']">Name cannot exceed {{EC2_NAME_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-12">
          <label for="ec2Country" class="form-label">Second Emergency Contact Country*</label>
          <input type="text" id="ec2Country" class="form-control" formControlName="ec2Country" [maxlength]="EC2_COUNTRY_MAX_CHARS">
          <div *ngIf="ec2Country?.invalid && (ec2Country?.dirty || ec2Country?.touched)" class="alert alert-danger">
            <div *ngIf="ec2Country?.errors?.['required']">Country is required.</div>
            <div *ngIf="ec2Country?.errors?.['maxlength']">Country cannot exceed {{EC2_COUNTRY_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-4">
          <label for="ec2Phone" class="form-label">Second Emergency Contact Phone*</label>
          <input type="tel" id="ec2Phone" class="form-control" formControlName="ec2Phone" [maxlength]="EC2_PHONE_NUMBER_MAX_CHARS">
          <div *ngIf="ec2Phone?.invalid && (ec2Phone?.dirty || ec2Phone?.touched)" class="alert alert-danger">
            <div *ngIf="ec2Phone?.errors?.['required']">Phone is required.</div>
            <div *ngIf="ec2Phone?.errors?.['maxlength']">Phone cannot exceed {{EC2_PHONE_NUMBER_MAX_CHARS}} characters.</div>
          </div>
        </div>
        <div class="mb-3 col-8">
          <label for="ec2Email" class="form-label">Second Emergency Contact Email*</label>
          <input type="email" id="ec2Email" class="form-control" formControlName="ec2Email" [maxlength]="EC2_EMAIL_MAX_CHARS">
          <div *ngIf="ec2Email?.invalid && (ec2Email?.dirty || ec2Email?.touched)" class="alert alert-danger">
            <div *ngIf="ec2Email?.errors?.['required']">Email is required.</div>
            <div *ngIf="ec2Email?.errors?.['maxlength']">Email cannot exceed {{EC2_EMAIL_MAX_CHARS}} characters.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <input type="checkbox" formControlName="acknowledge">
      <span style="padding-left: 5px;">By checking this box, I acknowledge that I am agreeing to the <a href="https://everywherecomms.com/pages/commercial-terms" target="_blank" rel="noopener noreferrer">Everywhere Communications Terms and Conditions</a>.</span>
    </div>
    <div class="mb-3" *ngIf="formSelection === EXISTING">
      <input type="checkbox" formControlName="acknowledgeTransfer">
      <span style="padding-left: 5px;">By checking this box, I authorize my IMEIs to be transferred to the EVERYWHERE Platform.</span>
    </div>
    <button class="btn btn-primary" type="submit" (click)="submit()" [disabled]="orderForm.invalid || processingSubmit">SUBMIT</button>
    <span *ngIf="processingSubmit">Processing...</span>
    <span *ngIf="postSubmitError" style="padding-left: 5px; color: red; font-weight: bold">Could not process order. </span>
    <span *ngIf="postSubmitError && postSubmitImeiError" style="padding-left: 5px; color: red; font-weight: bold">Please fix IMEIs.</span>
  </form>
</div>
<div *ngIf="formSubmitted" style="width: 70%; margin-right: auto; margin-left: auto; text-align: center">
  <h3 class="mb-3">Thank you! Your order has been received.</h3>
  <div class="mb-3" style="color:gray;">Order #{{orderNumber}}</div>
  <button class="btn btn-outline-primary" (click)="refreshForm()">start another order</button>
</div>
