<form [formGroup]="activationForm!">
  <div style="margin-top: 15px; margin-bottom: 15px;">
    <span>Bulk Upload CSV</span>
    <form style="display: none;">
      <input [disabled]="uploadingFile" type="file" (change)="onFileSelected($event)" onClick="this.form.reset()" #fileUpload>
    </form>
    <mat-icon style="vertical-align: bottom;" class="clickable" (click)="fileUpload.click()" matTooltip="select file">upload_file</mat-icon>
    <div *ngIf="uploadingFile">Processing file...</div>
  </div>
  <div *ngIf="uploadFileFailureMessage && !uploadingFile" style="white-space: pre-wrap;" class="alert alert-danger">{{uploadFileFailureMessage}}</div>
  <div *ngIf="order.imeiInfo && order.imeiInfo.length > 0">
    <div>
      <h3 style="display: inline">IMEIs Requested</h3>
      <span style="color: #0d6efd; opacity: 0.65;margin-left: 10px; vertical-align: bottom">
        <mat-icon aria-hidden="false" aria-label="Refresh IMEIs icon" class="clickable" matTooltip="Refresh IMEIs."
          (click)="refreshImeis()">refresh</mat-icon>
      </span>
      <span *ngIf="imeiRefreshFailed" style="color: red"> IMEI refresh failed. Please try again.</span>
    </div>
    <table>
      <thead>
        <tr>
          <th><input type="checkbox" formControlName="selectAll"></th>
          <th class="clickable" matTooltip="Click to copy IMEIs to clipboard."
            (click)="confirmImeisCopied()" [cdkCopyToClipboard]="copyImeis()">IMEI</th>
          <th>IMEI Status</th>
          <th>Activation Status</th>
          <th>Deactivation Status</th>
          <th>Hub Team Id</th>
          <th>Alias</th>
          <th>Dedicated SMS</th>
          <th>Email</th>
          <th>Secure inReach</th>
<!--          <th>Garmin Tenant</th>-->
          <th>JSON</th>
        </tr>
      </thead>
      <tbody formArrayName="devices">
      <tr *ngFor="let device of getDevices(); let i = index">
        <td><input name="{{order.imeiInfo[i].imei}}" type="checkbox" [formControlName]="i"></td>
        <td>{{order.imeiInfo[i].imei}}</td>
        <td>{{order.imeiInfo[i].imeiStatus}}</td>
        <td title="{{order.imeiInfo[i].validationErrors}}">{{order.imeiInfo[i].activationStatus}}</td>
        <td>{{order.imeiInfo[i].deactivationStatus}}</td>
        <td>{{order.imeiInfo[i].teamId}}</td>
        <td>{{order.imeiInfo[i].alias}}</td>
        <td>{{order.imeiInfo[i].dedicatedSmsCountryCode}}</td>
        <td>{{order.imeiInfo[i].emailAddress}}</td>
        <td>{{order.imeiInfo[i].secureInReach}}</td>
<!--        <td>{{order.imeiInfo[i].garminTenant}}</td>-->
        <td><mat-icon class="clickable" [cdkCopyToClipboard]="copyImeiInfoToClipboard(order.imeiInfo[i])"
            (click)="confirmImeiInfoCopied()" matTooltip="Copy full object as JSON">content_copy</mat-icon></td>
      </tr>
      </tbody>
    </table>
    <div style="margin-top: 15px;">
      <span>
        <button [disabled]="!devicesSelected()" class="mb-3 btn btn-outline-danger" (click)="deleteDevices()">Delete selected IMEI(s)</button>
      </span>
      <span style="margin-left: 15px;" *ngIf="order.orderType !== 'DEACTIVATION'">
        <button [disabled]="!canActivateDevices()" class="mb-3 btn btn-outline-primary" (click)="activateDevices()">Activate selected IMEI(s)</button>
      </span>
      <span style="margin-left: 15px;" *ngIf="order.orderType !== 'DEACTIVATION'">
        <button [disabled]="!canRevalidateDevices()" class="mb-3 btn btn-outline-primary" (click)="activateDevices()">Revalidate selected IMEI(s)</button>
      </span>
      <span style="margin-left: 15px;" *ngIf="order.orderType === 'DEACTIVATION' || order.orderType === 'REPLACEMENT'">
        <button [disabled]="!canDeactivateDevices()" class="mb-3 btn btn-outline-primary" (click)="deactivateDevices()">Deactivate selected IMEI(s)</button>
      </span>
    </div>
    <div class="alert alert-danger" *ngIf="displayActivationErrorMessage">
      Failed to activate device(s)
    </div>
    <div class="alert alert-danger" *ngIf="displayDeactivationErrorMessage">
      Failed to deactivate device(s)
    </div>
    <app-report [order]="order"></app-report>
  </div>
</form>
