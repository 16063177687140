import { CanActivateFn } from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {map} from "rxjs";
import {environment} from "../../environments/environment";

export const customerGuard: CanActivateFn = (route, state) => {
  return inject(AuthService).idTokenClaims$.pipe(map((idTokenClaims => {
    const roles: string[] = (idTokenClaims as any)['/roles'];
    return roles.includes(environment.customerRoleName);
  })));
}
