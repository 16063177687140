import {Component, inject, Input, ViewChild} from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef, MatDialogTitle
} from "@angular/material/dialog";
import {Order} from "../../model/order";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";
import {EditOrderFormComponent} from "../../actions/edit-order-form/edit-order-form.component";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {ActivationFormComponent} from "../../actions/activation-form/activation-form.component";
import {
  EditCustomerNameComponent
} from "../../actions/edit-customer-name/edit-customer-name.component";
import {OrderService} from "../../service/order.service";

const EDIT_CUSTOM_WIDTH = '40%';

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    NgIf,
    MatDialogTitle,
    MatButton,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,
    EditOrderFormComponent,
    MatIcon,
    MatTooltip,
    NgClass,
    ActivationFormComponent
  ],
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.css'
})
export class OrderDetailComponent {
  @Input() order!: Order;
  orderService = inject(OrderService);

  @ViewChild('activationForm') activationForm: ActivationFormComponent | undefined;

  public setOrder(order: Order) {
    this.order = order;
  }

  constructor(public dialogRef: MatDialogRef<OrderDetailComponent>, public dialog: MatDialog) {
  }

  editCustomerName() {
    const dialogRef = this.dialog.open(EditCustomerNameComponent, {
      width: EDIT_CUSTOM_WIDTH
    });
    dialogRef.componentInstance.setOrder(this.order);

    dialogRef.afterClosed().subscribe(() => {
      this.orderService.findById(this.order.orderId).subscribe((order) => {
        this.order = order;
      });
    });
  }
}
