import {Component, inject, Input} from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {COMPANY_NAME_MAX_CHARS, Order} from "../../model/order";
import {OrderService} from "../../service/order.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-edit-customer-name',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle
  ],
  template: `
    <h2 mat-dialog-title style="margin-left: 10%;">Update Company Name</h2>
    <form [formGroup]="editCustomerForm" class="edit-customer-form">
      <div class="mb-3" style="justify-content: left">
        <input id="companyName" class="form-control" type="text" autocomplete="off" formControlName="companyName" [maxlength]="COMPANY_NAME_MAX_CHARS" required>
      </div>
    </form>
    <mat-dialog-actions style="justify-content: center">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [disabled]="editCustomerForm.invalid" (click)="submit()">Update</button>
    </mat-dialog-actions>
  `,
  styleUrl: './edit-customer-name.component.css'
})
export class EditCustomerNameComponent {

  @Input() order!: Order;
  orderService = inject(OrderService);

  editCustomerForm: FormGroup = this.createForm();

  public setOrder(order: Order) {
    this.order = order;
  }

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<EditCustomerNameComponent>) {
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      companyName: [null, [Validators.required, Validators.maxLength(COMPANY_NAME_MAX_CHARS)]]
    });
  }

  get companyName() {
    return this.editCustomerForm.get('companyName');
  }

  submit() {
    if (this.companyName?.value && this.companyName.value.trim().length > 0) {
      this.order.companyName = this.companyName.value;
      this.orderService.updateOrder(this.order.orderId, this.order).subscribe(() => {
        this.dialogRef.close();
      });
    }
  }

  protected readonly COMPANY_NAME_MAX_CHARS = COMPANY_NAME_MAX_CHARS;
}
